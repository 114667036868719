import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Input, Img, Text, Button, Heading } from "../../../../../components";
import { Box, LinearProgress, Switch, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DefaultLayout from "layout/DefaultLayout";
import { ReactTyped } from "react-typed";
import AuthService from "services/authService";
import { CircularProgressBar, LinearProgressBar } from "components/ProgressBar";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

const theme = createTheme({
  palette: {
      primary: {
          main: '#4caf50', // Green color
      },
  },
});
const CIGIntro1= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 500 },
  });
  // const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isStandardVisible, setIsStandardVisible] = useState(false);
  const [isRegulationVisible, setRegulationIsVisible] = useState(false);
  const [isbuttonVisible, setbuttonIsVisible] = useState(false);

  const [isstandardInternationlVisible, setIsStandardInternationlVisible] = useState(false);
  const [isstandardRegulationlVisible, setIsStandardRegulationVisible] = useState(false);

  const [isregulationInternationlVisible, setIsRegulationInternationlVisible] = useState(false);
  const [isregulationRegionalVisible, setIsRegulationRegionallVisible] = useState(false);


  const dataOne = ["standard", "framework", "Regulations", ];
  const dataTwo = ["school", "class", "teacher","achievement", "home", "foods"];
  const [selectedOption, setSelectedOption] = useState('');


  const handleCategoryOptionChange = (event) => {
     setSelectedOption(event.target.value);
     if (event.target.value === "Standards") {
      setIsStandardVisible(true);
      setIsVisible(false);
      setRegulationIsVisible(false);
  }
    else{
      setIsStandardVisible(false);
      setIsVisible(false);
      setRegulationIsVisible(true);
    }
  };


  const [selectedSeconOption, setSelectedSeconOption] = useState<string[]>([]);
  const [selectedStandardOption, setSelectedStandardOption] = useState('');
  const [selectedRegulationOption, setSelectedRegulationOption] = useState('');

  const [isstandardInternational, setIsStandardInternationl] = useState('');
  const [isstandardRegulationl, setIsStandardRegulation] = useState('');

  const [isregulationInternational, setIsRegulationInternationl] = useState('');
  const [isregulationRegional, setIsRegulationRegional] = useState('');




  const handleStandardOptionChange = (event) => {
    setSelectedStandardOption(event.target.value);
    if (event.target.value === "International") {
    setIsStandardInternationlVisible(true);
    setIsStandardRegulationVisible(false);
 }
   else if (event.target.value === "regional") {
    setIsStandardInternationlVisible(false);
    setIsStandardRegulationVisible(true);
}
  
  };


  const handleRegulationOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };

  const handleRegulationInternationaOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };
  
  const handleRegulationRegionalOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };

  const handleStandardInternationaOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };

  const handleStandardRegionalOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };
  
  const [selectedMultiOptions, setSelectedMultiOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: string }>({});
  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleCheckboxChange = (value: string) => {
    setSelectedMultiOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value]
    );

    // Optional: You can also update selectedOptions if necessary
    if (value === 'standard_sasb' || value === 'standard_gri' || value === 'standard_issb') {
      handleSelection(4, value);
    }

    // If currentStep is 5 or more, set it to 6
    if (currentStep >= 5) {
      setCurrentStep(6);
    }
  };

  const handleSelection = (step: number, option: string) => {
    setSelectedOptions({ ...selectedOptions, [step]: option });
    setCurrentStep(step + 1); // Move to next step
  };
  
  
  
  return (
    <>
     <Helmet>
        <title>SALI | Intro</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="h-screen py-4 px-6 bg-[#F5F4F4] overflow-scroll flex flex-col ">
      <div className="flex flex-col gap-2">
      <Link
       to="/dashboard/module"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 border border-[#000000] w-28 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-md mb-10 flex flex-row gap-2 items-center"
       >
        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.455528 5.95553C0.154824 6.25623 0.154824 6.74377 0.455528 7.04447L5.35578 11.9447C5.65648 12.2454 6.14402 12.2454 6.44472 11.9447C6.74543 11.644 6.74543 11.1565 6.44472 10.8558L2.08894 6.5L6.44472 2.14422C6.74543 1.84352 6.74543 1.35598 6.44472 1.05528C6.14402 0.754574 5.65648 0.754574 5.35578 1.05528L0.455528 5.95553ZM1 7.27H16.7877V5.73H1V7.27Z" fill="black"/>
</svg>
                     <button
          className="w-full"
        >
            Back
        </button>
      </Link>

      <div className="flex flex-row sm:flex-col gap-2 ">
      <div 
    className={`transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-[#5BBA47]  rounded-md ${
      currentStep >= 2 && selectedOptions[1] === 'Surgical&MedicalTools'?
      'bg-[#5BBA47] text-white-A700':'bg-[#EEFFEF] text-[#5BBA47]'
    }`}
  >

    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
          type="radio"
          className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-full bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 "
          value="Surgical&MedicalTools"
          onChange={() => handleSelection(1, 'Surgical&MedicalTools')}
          checked={selectedOptions[1] === 'Surgical&MedicalTools'}
        />
        Surgical & Medical Tools
      </label>
      </div>
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          className="border-black-900_01 border-opacity-20"
          value="MedicalConsumables"
          // onChange={() => handleSelection(1, 'MedicalConsumables')}
         
          checked={selectedOptions[1] === 'MedicalConsumables'}
        />
        Medical Consumables
      </label>
    </div>

      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          value="guidance&codesofpractices"
          className="border-black-900_01 border-opacity-20"
          checked={selectedOption === 'codesofpractices'}
        />
        Patient Care Items
      </label>
      </div>
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          value="guidance&codesofpractices"
          className="border-black-900_01 border-opacity-20"
          checked={selectedOption === 'codesofpractices'}
        />
        Clothing & Textiles
      </label>
      </div>
      </div>
      </div>
     
       {currentStep >= 2 && selectedOptions[1] === 'Surgical&MedicalTools' && (
        <div>
          <div className="flex flex-wrap sm:flex-col gap-2 mt-20">
          <div 
    className={`transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-[#5BBA47]  rounded-md ${
      currentStep >= 2 && selectedOptions[2] === 'scissors'?
      'bg-[#5BBA47] text-white-A700':'bg-[#EEFFEF] text-[#5BBA47]'
    }`}
  >

    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
          type="radio"
          className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-full bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 "
          value="scissors"
          onChange={() => handleSelection(2, 'scissors')}
          checked={selectedOptions[2] === 'scissors'}
        />
       Scissors
      </label>
      </div>
  
  <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
  <label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
      <input
          type="radio"
          // name="regulation_type"
          value="Regional"
          className="border-black-900_01 border-opacity-20"
          onChange={() => handleSelection(3, 'Regional')}
          checked={selectedOptions[2] === 'Regional'}
      />
      Scalpel Blades
    </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
      <input
        type="radio"
        value="countyspecific"
        className="border-black-900_01 border-opacity-20"
        // onChange={() => handleSelection(2, 'Regional')}
        checked={selectedOptions[2] === 'countyspecific'}
      />
      Forceps
    </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start  justify-start border border-gray-400 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
    <input

        type="radio"
        className="border-black-900_01 border-opacity-20"
        value="clamps"
        //  onChange={() => handleSelection(2, 'international_regulations')}
        checked={selectedOptions[2] === 'clamps'}
    />
   Clamps
  </label>
</div>
<div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
<label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
    <input
        type="radio"
        // name="regulation_type"
        value="Regional"
        className="border-black-900_01 border-opacity-20"
        onChange={() => handleSelection(3, 'Regional')}
        checked={selectedOptions[2] === 'Regional'}
    />
    Retractors
  </label>
  </div>
  <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
  <label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
    <input
      type="radio"
      value="countyspecific"
      className="border-black-900_01 border-opacity-20"
      // onChange={() => handleSelection(2, 'Regional')}
      checked={selectedOptions[2] === 'countyspecific'}
    />
    Needle Holders
  </label>
  </div>
      </div>
        </div>
      )}
    
                 {(currentStep >= 3) && (
         <div className="flex flex-col gap-2 mt-20">
            
              <div className="w-full flex flex-row items-center justify-center  animate__animated animate__fadeInUp">
       <Link
        to="/dashboard/question/cig"
        className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 hover:bg-[#5BBA47] text-white-A700 bg-[#1AC420]  mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
        >
        <button
         className="w-full" >
         Start Assessment
         </button>
       </Link>
       </div>
       
        </div>
      )}
    </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {CIGIntro1};
const Intro2= () =>  {

const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });
  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/intro1"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
    
       
    
      <Text  className="text-3xl md:text-2xl sm:text-xl"
      >
      In which transportation subsector are you involved?
              </Text>
     <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="/dashboard/module/intro3"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway
        </button>
                    </Link>
  
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Road
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Aviation
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-md"
        >
         Marine
        </button>
      </div>
      </animated.div> 

        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro2};
const Intro3= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   
  <animated.div ref={ref} style={animation}>
      <div className="w-full md:flex-col p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/intro2"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
       
      
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      <Link
       to="/dashboard/module/intro4"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Light Rail
        </button>
                    </Link>
  
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Heavy Rail
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Metro
        </button>
      </div>
        

        

      </div>
      </animated.div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro3};
const Intro4= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="w-full p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/intro3"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
       
      
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="/dashboard/module"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway Owner
        </button>
                    </Link>
  
        <button
          className=" transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Railway Operator
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Railway Maintaner
        </button>
      </div>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Engineering and Construction
        </button>
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Manufacturing ( Trains, Signaling,
Track, Communication Systems)
        </button>
      </div>
      </animated.div>
        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro4};