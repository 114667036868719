import React, { useState, useEffect } from 'react'
import { Button, Text, Input, SelectBox, Img } from "../../components";

import { useNavigate } from 'react-router-dom';
import Animate from 'animate.css-react'
 
import 'animate.css/animate.css'  //

// import Logo from "../../assets/svg/Logo.svg"

import { ReactTyped } from 'react-typed';
import AuthService from 'services/authService';
import MyComponent from './component/steper';

const HomeAnimation = () => {
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showButton, setShowButton] = useState(false)
    const userData = AuthService.getUserData();
    // Access the firstname and lastname properties directly from the userData object
    const { firstname, lastname } = userData;

    const navigate = useNavigate()
  

    const showText = () => {
        setTimeout(() => {
            setShow(true)
        }, 10000)
    };
    const showTextTwo = () => {
        setTimeout(() => {
            setShowTwo(true)
        }, 10500)
    };

    const showNavButton = () => {
        setTimeout(() => {
            setShowButton(true)
        }, 11000)
    };

    useEffect(() => {
        showText();
        showTextTwo();
        showNavButton()
    }, [])

  return (
    <div className='flex flex-col bg-[#F5F4F4C4] h-screen overflow-scroll'>
             <nav className="flex self-stretch justify-between items-center gap-5 p-5 md:p-4 fixed w-full z-10  bg-gradient5" >
      <div className="container mx-auto flex justify-between items-center">
      <Img
                  src="/images/img_settings_cyan_900.svg"
                  alt="settings_one"
                  className="h-8 md:h-6"
      />
        <div className="  flex flex-row space-x-4 items-center">
     
        </div>
      </div>
    </nav>
        <div className='flex flex-col items-center justify-start mt-48   py-3'> 
            <ReactTyped 
                strings={[
                    `Hello <span 
                    style="background: -webkit-linear-gradient(#5BBA47, #36A73A);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;">${firstname} ${lastname}</span>`,
                    `I'M <span 
                    style="background: -webkit-linear-gradient(#5BBA47, #36A73A);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;">S.A.L.I</span>`,
                ]} 
                typeSpeed={120} 
                backSpeed={140}
                className='font-Kumbh font-medium text-6xl md:text-5xl sm:text-3xl'
            />
            
             <p className={`${show ? "mt-10 font-Kumbh font-normal text-2xl md:text-xl text-center sm:text-xl text-[#000] animate__animated animate__fadeInUp" : "hidden"} `}>
                (Sustainability Assessment Learning Intelligence)
            </p>
            <p className={`${showTwo ? "mt-5 font-Kumbh text-base w-[60%] md:w-[90%] text-center animate__animated animate__fadeInUp " : " hidden"}`}> 
                your virtual assistant dedicated to guiding you on your journey towards 
                sustainability and compliance with the SDGs.
            </p>
            <div className={`${showTwo ? "my-10 font-Kumbh font-semibold text-2xl md:text-xl w-[60%] md:w-[90%] text-center animate__animated animate__fadeInUp " : " hidden"}`}> 
      <span >Steps to begin your</span>
      <span className='text-green-A700_01'> assessment journey</span>
      <MyComponent/>

    </div>
            <button
                onClick={() => navigate("/dashboard/module/intro1")}
                className={`${showButton ? "mb-10 border rounded-[29px]  text-center w-auto px-20  text-[14px] md:text-base sm:text-sm font-Kumbh text-base font-medium p-4 border-[#000]" : "hidden"}`}
            >
                Continue
            </button>
        </div>
       
    </div>
  )
}

export default HomeAnimation