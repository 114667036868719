import axios, { AxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios';
import TokenService from './token';
import { Bounce, toast } from 'react-toastify';
import questionsData from '../assets/sali.questions.json'; // Import the JSON file
import energyquestionsData from '../assets/energy.questions.json'; // Import the JSON file
import wastequestionsData from '../assets/waste.questions.json'; // Import the JSON file



interface User {
   firstname:string;
  lastname:string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  companySize: string;
  password: string;
}
 interface DemoUser {
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  companyRegistrationNumber: string;
  companySize: string;
  companySector: string;
  companySubSector: string;

}
 


interface UserData {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  companySize: string;
  companyUsers: any[];
  surveys: any[];
  cert: any[];
  __v: number;
}

// const dummyUserData: UserData = {
//   _id: '12345',
//   firstname: 'NHS',
//   lastname: 'Scotland',
//   email: 'demov1@absoluterisktec.co.uk',
//   companyName: 'ART',
//   position: 'Executive',
//   sector: 'Technology',
//   industry: 'Software',
//   companySize: '10-20',
//   companyUsers: [
    
//     { userId: '2', name: 'Bob', email: 'bob@techcorp.com', role: 'Engineer' }
//   ],
//   surveys: [
//     { surveyId: 's2', title: 'Market Research', status: 'In Progress', createdAt: '2024-02-01' }
//   ],
//   cert: [
//     { certId: 'c1', certName: 'ISO Certification', issuedAt: '2024-01-15' },
//   ],
//   __v: 0,
//   password: ''
// };
const dummyUserData: UserData = {
  _id: '12345',
  firstname: 'Demo',
  lastname: 'User',
  email: 'demov1@absoluterisktec.co.uk',
  companyName: 'ART',
  position: 'Executive',
  sector: 'Technology',
  industry: 'Software',
  companySize: '10-20',
  companyUsers: [
    
    { userId: '2', name: 'Bob', email: 'bob@techcorp.com', role: 'Engineer' }
  ],
  surveys: [
    { surveyId: 's2', title: 'Market Research', status: 'In Progress', createdAt: '2024-02-01' }
  ],
  cert: [
    { certId: 'c1', certName: 'ISO Certification', issuedAt: '2024-01-15' },
  ],
  __v: 0,
  password: ''
};
interface AuthResponse {
  success: boolean;
  error?: string;
  user?: UserData;
}

interface NewsletterResponse {
  success: boolean;
  error?: string;
}

interface UserData {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  companySize: string;
  password: string;
  companyUsers: any[]; 
  surveys: any[];
  cert: any[]; 
  __v: number;
}

interface SelectedData {
  question: string;
  answer: any; // Adjust this type according to your requirements
}



interface AssignedOption {
  text: string;
  evd: string;
  tips: string;
  evdText: string;
  keyWord: string;
  point: number;
  _id: string;
}

interface QuestionDetails {
  _id: string;
  sector: string;
  subSector: string;
  type: string;
  industryFuntion: string;
  userFuntion: string;
  selectAssessmentCat: string;
  selectComplianceCat: string;
  text: string;
  tips: string;
  point: number;
  options: AssignedOption[];
  __v: number;
}

interface AssignedQuestion {
  _id: string;
  question: QuestionDetails;
  email: string;
  assignedAt: string;
  __v: number;
}

interface ResponseData {
  assignedQuestions: AssignedQuestion[];
}
interface Option {
  text: string;
  evd: string;
  tips: string;
  evdText: string;
  keyWord: string;
  point: number;
  _id: string;
}

interface Question {
  _id: string;
  sector: string;
  subSector: string;
  type: string;
  industryFunction: string;
  userFunction: string;
  selectAssessmentCat: string;
  selectComplianceCat: string;
  text: string;
  tips: string;
  point: number;
  options: Option[];
  jurisdiction: string;
  scheme: string;
  body: string;
  tsi: string;
  ntsn: string;
  __v: number;
}
class AuthService {

  static async login(email: string, password: string): Promise<{ success: boolean, user?: UserData, error?: string }> {
    const adminEmail = 'demov1@absoluterisktec.co.uk';
    const adminPassword = 'Password';

    // Check credentials
    if (email === adminEmail && password === adminPassword) {
      // Simulate saving token and user data
      const token = 'dummyToken123';
      localStorage.setItem('authToken', token);
      localStorage.setItem('userData', JSON.stringify(dummyUserData));

      // Show success notification
      toast.success('Logged In Successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return { success: true, user: dummyUserData };
    } else {
      // Show error notification
      toast.error('Invalid email or password. Please try again', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return { success: false, error: 'Invalid email or password. Please try again.' };
    }
  }
  static async sendnewletter(email: string): Promise<NewsletterResponse> {
    try {
      const response = await axios.post(`https://saudit-jheg.onrender.com/newsletter/subscribe`, {
        email,
      });
      if (response.status === 200) {
        toast.success('Subscribed to newsletter successfully.', {
          position:"top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        console.log(response);
        return { success: true, };
      } else if (response.status === 400) {
        toast.warn('Email already subscribed to newsletter.', {
          position:"top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        return { success: false, };
      }
     
    } catch (error) {
      toast.warn('Email already subscribed to newsletter.', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return { success: false, error: 'Error occcued. Please try again.' };
    }
  }
  static async register(userData: DemoUser): Promise<AuthResponse> {
    try {
      const response = await axios.post('https://api-dev.sali-ai.com/api/client-demo', userData);

      const { token, user } = response.data;
      toast.success('Demo sent  Successfully', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
     console.log('sucessfull:', response);
    
      return { success: true, user };
    } catch (error) {
      console.error('Demo error:', error);
      toast.error('Demo failed. Please try again.', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      return { success: false, error: 'Demo Registration failed. Please try again.' };
    }
  }

  static sendEmail = async (email: string, name: string) => {
    try {
      const response = await axios.post(
        "https://sali-send-email-api.vercel.app/api/send-email",
        {
          from: 'Demo <noreply@sali-ai.com>',
          to: email, // Use the provided email parameter
          subject: "Thank you for your interest in SALI!",
          html: `
  
         <html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Email Template</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f1f1f1;
            margin: 0;
            padding: 0;
        }
        .container {
            max-width: 600px;
            background-color: #ffffff;
            margin: 0 auto;
            padding: 20px;
            border-radius: 10px;
        }
        .header {
            background-color: #2ba64d;
            padding: 20px;
            text-align: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .header h1 {
            color: white;
            font-size: 24px;
            margin: 0;
        }
        .header p {
            color: #e3f4e9;
            font-size: 18px;
            margin: 0;
        }
        .content {
            padding: 20px;
            font-size: 16px;
            color: #333333;
        }
        .content h2 {
            color: #333;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .content p {
            margin: 0 0 10px;
            line-height: 1.6;
        }
        .content .highlight {
            color: #2ba64d;
            font-weight: bold;
        }
        .footer {
            text-align: center;
            padding: 20px;
            font-size: 12px;
            color: #999999;
        }
        .social-icons {
            margin: 10px 0;
        }
        .social-icons img {
            margin: 0 10px;
            width: 30px;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <h1>We will be in touch soon.</h1>
            <p>Let's Dive into Sustainability</p>
        </div>
        <div class="content">
          <h2 class="welcome-banner">Dear ${name},</h2>
          <p class="welcome-message">
            <br />
            Thank you for requesting a demo of SALI. 
            <br />
            We're excited to learn more about your organization's sustainability goals and how SALI can help you achieve them. 
            <br />
            <br />
            A member of our team will be in touch shortly to schedule a personalized demo tailored to your specific needs. 
            <br />
            <br />
            In the meantime, please feel free to explore our website for more information about SALI and its features. 
            <br />
            <br />
            Thank you again for your interest. Best regards, 
            <br />
            <br />
            The SALI Team 
            <br />
            <br />
          </p>
          
        </div>
        <div class="footer">
            <div class="social-icons">
                <img src="linkedin-icon-url" alt="LinkedIn">
                <img src="website-icon-url" alt="Website">
                <img src="facebook-icon-url" alt="Facebook">
            </div>
            <p>Copyright © 2024 SALI, All rights reserved.</p>
        </div>
    </div>
</body>
</html>

          `,
        },
        {
          headers: {
            // Uncomment and add your actual authorization token if needed
            // Authorization: `Bearer YOUR_AUTH_TOKEN`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error sending email:", error.response?.data || error.message || error);
    }
  };
  static InternalEmail = async (email: string, name: string,companyName: string ) => {
    try {
      const response = await axios.post(
        "https://sali-send-email-api.vercel.app/api/send-email",
        {
          from: 'Demo <noreply@sali-ai.com>',
          to: email, // Use the provided email parameter
          subject:`New Demo Request from ${companyName}`,
          html: `
  
         <html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Email Template</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f1f1f1;
            margin: 0;
            padding: 0;
        }
        .container {
            max-width: 600px;
            background-color: #ffffff;
            margin: 0 auto;
            padding: 20px;
            border-radius: 10px;
        }
        .header {
            background-color: #2ba64d;
            padding: 20px;
            text-align: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .header h1 {
            color: white;
            font-size: 24px;
            margin: 0;
        }
        .header p {
            color: #e3f4e9;
            font-size: 18px;
            margin: 0;
        }
        .content {
            padding: 20px;
            font-size: 16px;
            color: #333333;
        }
        .content h2 {
            color: #333;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .content p {
            margin: 0 0 10px;
            line-height: 1.6;
        }
        .content .highlight {
            color: #2ba64d;
            font-weight: bold;
        }
        .footer {
            text-align: center;
            padding: 20px;
            font-size: 12px;
            color: #999999;
        }
        .social-icons {
            margin: 10px 0;
        }
        .social-icons img {
            margin: 0 10px;
            width: 30px;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <h1>We will be in touch soon.</h1>
            <p>Let's Dive into Sustainability</p>
        </div>
        <div class="content">
          <h2 class="welcome-banner">Dear ${name},</h2>
          <p class="welcome-message">
            <br />
            Thank you for requesting a demo of SALI. 
            <br />
            We're excited to learn more about your organization's sustainability goals and how SALI can help you achieve them. 
            <br />
            <br />
            A member of our team will be in touch shortly to schedule a personalized demo tailored to your specific needs. 
            <br />
            <br />
            In the meantime, please feel free to explore our website for more information about SALI and its features. 
            <br />
            <br />
            Thank you again for your interest. Best regards, 
            <br />
            <br />
            The SALI Team 
            <br />
            <br />
          </p>
          
        </div>
        <div class="footer">
            <div class="social-icons">
                <img src="linkedin-icon-url" alt="LinkedIn">
                <img src="website-icon-url" alt="Website">
                <img src="facebook-icon-url" alt="Facebook">
            </div>
            <p>Copyright © 2024 SALI, All rights reserved.</p>
        </div>
    </div>
</body>
</html>

          `,
        },
        {
          headers: {
            // Uncomment and add your actual authorization token if needed
            // Authorization: `Bearer YOUR_AUTH_TOKEN`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error sending email:", error.response?.data || error.message || error);
    }
  };
  static logout(): void {
    // Clear token and user data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    toast("Logged out Successfully", {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
    });
  }

  static isLoggedIn(): boolean {
    // Check if token exists in local storage
    return !!localStorage.getItem('token');
  }
  

  static async sendSelectedData(selectedData: SelectedData[]): Promise<boolean> {
    try {
      const response = await axios.put(
        'https://saudit-jheg.onrender.com/auth/update',
        { surveys: selectedData }, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getToken()}`,
          },
        }
      );
     
      if (response.status === 200) {
        console.log('Data sent successfully');
        console.log(response);
        console.log(selectedData);
        return true;
      } else {
        console.error('Failed to send data');
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  static async fetchQuestions(): Promise<Question[]> {
    try {
      // const userData = this.getUserData();
      // if (!userData || !userData._id) {
      //   throw new Error('User data not found or invalid');
      // }

      // const userFunction = userData.position;
      // console.log('Position:', userFunction);
      // const sector = 'Transport'; 
      // const filteredQuestions: Question[] = questionsData.filter((question: Question) => {

      //   return question.sector === sector && question.industryFunction === userFunction;

      // });

      // // Log the filtered questions for debugging
      // console.log('Filtered Questions:', filteredQuestions);

      return questionsData;
    } catch (error) {
      console.error('Error fetching questions:', error);
      return [];
    }
  }
  static async fetchEnergyQuestions(): Promise<Question[]> {
    try {
      // const userData = this.getUserData();
      // if (!userData || !userData._id) {
      //   throw new Error('User data not found or invalid');
      // }

      // const userFunction = userData.position;
      // console.log('Position:', userFunction);
      // const sector = 'Transport'; 
      // const filteredQuestions: Question[] = energyquestionsData.filter((question: Question) => {

      //   return question.sector === sector && question.industryFunction === userFunction;

      // });

      // // Log the filtered questions for debugging
      // console.log('Filtered Questions:', filteredQuestions);

      return energyquestionsData;
    } catch (error) {
      console.error('Error fetching questions:', error);
      return [];
    }
  }
  static async fetchWasteQuestions(): Promise<Question[]> {
    try {
      // const userData = this.getUserData();
      // if (!userData || !userData._id) {
      //   throw new Error('User data not found or invalid');
      // }

      // const userFunction = userData.position;
      // console.log('Position:', userFunction);
      // const sector = 'Transport'; 
      // const filteredQuestions: Question[] = energyquestionsData.filter((question: Question) => {

      //   return question.sector === sector && question.industryFunction === userFunction;

      // });

      // // Log the filtered questions for debugging
      // console.log('Filtered Questions:', filteredQuestions);

      return wastequestionsData;
    } catch (error) {
      console.error('Error fetching questions:', error);
      return [];
    }
  }
  static async fetchAssgnQuestions(): Promise<AssignedQuestion[]> {
    const userData = this.getUserData();
    if (!userData || !userData.email) {
      throw new Error('User data not found or invalid');
    }
    
    const email = userData.email;
    try {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getToken()}`,
        },
      };
      
      const requestBody = {
        email: email
      };

      const response = await axios.post<ResponseData>('https://saudit-jheg.onrender.com/surveys/getAssQuestion', requestBody, config);
      return response.data.assignedQuestions;
    } catch (error) {
      console.error('Error fetching questions:', error);
      return [];
    }
  }
  static async fetchCerts() {
    try {
      const userData = this.getUserData();
      if (!userData || !userData._id) {
        throw new Error('User data not found or invalid');
      }
      
      const id = userData._id;
      const response = await axios.get(`https://saudit-jheg.onrender.com/auth/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getToken()}`, // Adjust based on your token retrieval logic
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching certs:', error);
      throw error; // Handle this error in the component where you call this method
    }
  }

  static getToken(): string {
    return localStorage.getItem('token') || '';
  }
  static getUserData(): UserData | null {
    const userDataString = localStorage.getItem('userData');
    if (userDataString) {
      return JSON.parse(userDataString);
    }
    return null;
  }

}

export default AuthService;
