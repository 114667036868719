import React from "react";

const ResendLink: React.FC = () => {

    return (
  
      <p className="text-base leading-9 text-black">
  
        Didn't receive email?{' '}
  
        <button className="text-emerald-500">Resend verification</button>
  
      </p>
  
    );
  
  };
  
  
  export default ResendLink;
  
  