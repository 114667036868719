import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import NotFound from "pages/NotFound";
import LandingPage from "pages/LandingPage";
import SignupPage from "pages/Auth/SignupPage";
import QuickChat from "pages/QuickChat";
import Login from "pages/Auth/Login";
import ForgetPassword from "pages/Auth/ForgetPassword";
import HomeWecome from "pages/Welcome";
import CompanyProfile from "pages/Dashboard/CompanyAdminProfile";
import Module from "pages/Dashboard/Module";
import DocManager from "pages/Dashboard/DocManager";
import Questions from "pages/Dashboard/Questions/ClimateChange/question";
import checkAuth from "services/check_auth";
import Scores from "pages/Dashboard/Questions/ClimateChange/Score/Score";
import { Intro1, Intro2, Intro3, Intro4 } from "pages/Dashboard/Module/intro";
import { ClimateIntro1, ClimateIntro2, ClimateIntro3, ClimateIntro4, ClimateIntro5 } from "pages/Dashboard/Module/climate-intro";
import Questionnaire from "pages/Dashboard/Questions/ClimateChange/question";
import AskSaliPage from "pages/AskSali";
import AddDocs from "pages/Dashboard/DocManager/pages/addDocs";
import AddCertificate from "pages/Dashboard/DocManager/pages/addCertificate";
import AssignSignupPage from "pages/Assign/SignupPage";
import AssignloginPage from "pages/Assign/Login";
import AssignConfirmEmailPage from "pages/Assign/ConfirmEmail";
import AssignQuizz from "pages/Assign/Questions/ClimateChange/question";
import AddDocsListing from "pages/Dashboard/DocManager/pages/addDocsListing";
import SustainabilityJourney from "pages/pricing";
import ReportScreen from "pages/Dashboard/Questions/report/Score";
import EnergyQuestions from "pages/Dashboard/Questions/CIG/question";
import WasteQuestions from "pages/Dashboard/Questions/Waste/question";
import CIGQuestions from "pages/Dashboard/Questions/CIG/question";
import { CIGIntro1 } from "pages/Dashboard/Questions/CIG/intro";
import CIGReportScreen from "pages/Dashboard/Questions/report/CIGScore";
import WasteReportScreen from "pages/Dashboard/Questions/report/WasteScore";


const ProjectRoutes = () => {


  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/landingpage" element={<LandingPage />} />
      <Route path="/signuppage" element={<SignupPage />} />
      <Route path="/login" element={ <Login />} />
      <Route path="/forgotpassword" element={<ForgetPassword />} />
      <Route path="/pricing" element={<SustainabilityJourney />} />
      <Route path="/dashboard/welcome" element={ <HomeWecome /> } />
      <Route path="/dashboard/home" element={<CompanyProfile /> } />
      <Route path="/dashboard/module" element={ <Module /> } />
      
      <Route path="/dashboard/module/intro1" element={ <Intro1 /> } />
      <Route path="/dashboard/module/intro2" element={ <Intro2 /> } />
      <Route path="/dashboard/module/intro3" element={ <Intro3 /> } />
      <Route path="/dashboard/module/intro4" element={ <Intro4 /> } />

      <Route path="/dashboard/module/climate-intro1" element={ <ClimateIntro1 /> } />
      <Route path="/dashboard/module/climate-intro2" element={ <ClimateIntro2 /> } />
      <Route path="/dashboard/module/climate-intro3" element={ <ClimateIntro3 /> } />
      <Route path="/dashboard/module/climate-intro4" element={ <ClimateIntro4 /> } />
      <Route path="/dashboard/module/climate-intro5" element={ <ClimateIntro5 /> } />

      <Route path="/dashboard/doc-manager" element={<DocManager /> } />

      <Route path="/dashboard/question/climate-change" element={ <Questionnaire /> } />
      <Route path="/dashboard/question/cig" element={ <CIGQuestions /> } />
      <Route path="/dashboard/question/cig/intro" element={ <CIGIntro1 /> } />
      <Route path="/dashboard/question/waste" element={ <WasteQuestions /> } />
      <Route path="/dashboard/question/climate-change/score/:score" element={ <Scores /> } />
      <Route path="/dashboard/question/report" element={ <ReportScreen /> } />
      <Route path="/dashboard/question/cig/report" element={ <CIGReportScreen /> } />
      <Route path="/dashboard/question/waste/report" element={ <WasteReportScreen /> } />
      <Route path="/Ask-sali" element={ <AskSaliPage /> } />


      <Route path="/dashboard/doc-manager/add-certifications" element={<AddCertificate /> } />
      <Route path="/dashboard/doc-manager/add-docs-listing" element={<AddDocsListing /> } />
      <Route path="/dashboard/doc-manager/add-docs" element={<AddDocs /> } />


      <Route path="/assign-user/signup" element={<AssignSignupPage /> } />
      <Route path="/assign-user/confirm-email" element={<AssignConfirmEmailPage /> } />
      <Route path="/assign-user/login" element={<AssignloginPage /> } />
      <Route path="/assign-user/question" element={<AssignQuizz /> } />

    </Routes>
  );
};

export default ProjectRoutes;
