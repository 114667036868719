import React from 'react';
import { Button, Text, Input, SelectBox, Img } from "../../../../components";
import { CircularProgress } from '@mui/material';
import ResendLink from './ResendLink';
import { useNavigate } from 'react-router-dom';

interface VerifiedTabProps {
  handleTabChange: (tab: number) => void;

  loading: boolean;
}

const VerifiedTab: React.FC<VerifiedTabProps> = ({  handleTabChange, loading }) => {
  const navigate = useNavigate()

const handleSubmit = async () => {
  navigate("/pricing");
};

  return (
    <div className="w-full h-full mt-10">
    <Text size="13xl" as="p">
    You’re Verified! 🎉
   </Text>


<div className="w-full">

<section className="self-stretch mt-2 mb-8 w-full text-sm leading-9 text-[#4E4E4E] ">

<p>

Your email has been successfully verified. 

<br />

You’re all set to start exploring!

</p>

</section>

{loading ? (
<button  onClick={() => handleSubmit()} className= "mb-14 transition w-[259px] md:h-full ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
<CircularProgress size={24} />
</button>
) : (
<button onClick={() => handleSubmit()}  className= "mb-14 transition w-[259px] md:h-full   ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300  sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
 Continue
</button>
)}

<ResendLink />


</div>
</div>
  );
};

export default VerifiedTab;
