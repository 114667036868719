import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import { Input, Img, Text, Button, Heading, RadioGroup, Radio } from "../../../components";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgressBar } from "components/ProgressBar";
import { ReactTyped } from "react-typed";
import AuthService from "services/authService";
import RightSidebar from "./component/adduser";


import SidebarMenu from "./component/editiuser";
import AddUserSidebarMenu from "./component/adduser";
import AddCertificateSidebarMenu from "./component/addCertificate";
import { Switch } from "@mui/material";
import { Bounce, ToastContainer, toast } from "react-toastify";
const theme = createTheme({
  palette: {
      primary: {
          main: '#4caf50', // Green color
      },
  },
});
export default function CompanyAdminProfilePage() {
  const [searchBarValue8, setSearchBarValue8] = React.useState("");
  const [progress, setProgress] = useState(0);
  const navbarRef = useRef(null);
  const userData = AuthService.getUserData();
  // Access the firstname and lastname properties directly from the userData object
  const { firstname, lastname,email } = userData;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isAddUserOpen, setAddUserOpen] = useState(false);
  const handleAddUserToggle = () => {
    setAddUserOpen(!isAddUserOpen);
    disableBodyScroll();
  };
  // Access the firstname and lastname properties directly from the userData object
  const handleCloseAddUser = () => {
    setAddUserOpen(false);
    enableBodyScroll();
  };
  const [isAddCertificateOpen, setAddCertificateOpen] = useState(false);
  const handleAddCertificateToggle = () => {
    setAddCertificateOpen(!isAddCertificateOpen);
    disableBodyScroll();
  };
  // Access the firstname and lastname properties directly from the userData object
  const handleCloseAddCertificate = () => {
    setAddCertificateOpen(false);
    enableBodyScroll();
  };
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
    disableBodyScroll();
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    enableBodyScroll(); // Re-enable body scrolling
  };

  const handleComingSoon = () => {
    toast.warn('This feature is coming soon!', {
      position:"top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
  };
  // Function to disable body scrolling
  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  // Function to enable body scrolling
  const enableBodyScroll = () => {
    document.body.style.overflow = '';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
        setAddUserOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress === 50) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [progressValue, setProgressValue] = useState(0);
  const maxProgress = 100;
  const targetProgress = 0;
  const [introcomplete, setintroComplete] = useState(false);

  useEffect(() => {
    // Check local storage for user data
    const userData = localStorage.getItem('introcomplete');
    if (userData) {
      // If user data is found, consider the user logged in
      setintroComplete(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressValue < targetProgress) {
        setProgressValue(progressValue + 1);
      } else {
        clearInterval(interval);
      }
    }, 100); // Change the interval time as needed
    return () => clearInterval(interval);
  }, [progressValue, targetProgress]);


  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>

    <nav className="flex self-stretch justify-between items-center gap-5 p-5 md:p-4 fixed w-full z-10  md:bg-gray-200 bg-white-A700" >
      <div className="container mx-auto flex justify-between items-center">
      <NavLink to="/">
       <Img
                  src="/images/img_settings_cyan_900.svg"
                  alt="settings_one"
                  className="h-8"
          />
      </NavLink>
      
        <div className=" md:hidden w-[40%]">
        <div onClick={handleComingSoon} className="cursor-pointer flex items-center  border  rounded-xl bg-gray-200 border-gray-200 p-3 mt-5 gap-4">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.275 9.55C7.63602 9.55 9.55 7.63602 9.55 5.275C9.55 2.91398 7.63602 1 5.275 1C2.91398 1 1 2.91398 1 5.275C1 7.63602 2.91398 9.55 5.275 9.55Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.0001 10L9.1001 9.10001" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
            {/* <input
                placeholder={'Search File'}
                className=" ml-2 flex-grow outline-none bg-transparent text-xs"
            /> */}
            <div className="text-gray-500 text-base"> Search File</div>
            
        </div> 
        </div>

        <div className="flex justify-center items-center ">
             <Img
                    src="/images/img_user_blue_gray_900_05.svg"
                    alt="user_one"
                    className="h-6 w-6 "
                  />
                <Img src="/images/img_inbox.svg" alt="inbox_one" className="sm:hidden h-6 w-6  ml-3" />
                <Text
                  size="7xl"
                  as="p"
                  className="ml-3 flex justify-center items-center h-6 w-6 !text-white-A700 !font-hankengrotesk bg-blue-600 text-center rounded-full"
                >
                  {userData?.firstname ? userData.firstname.charAt(0) : ''}
                </Text>
              </div>
      </div>

      <div className="hidden md:block  ">
          {isMenuOpen ? (
            <button 
              onClick={handleCloseMenu}
              className="text-gray-800 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button 
              onClick={handleMenuToggle}
              className="text-gray-800 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          )}
        </div>
    
    </nav>
   
      <div className="w-full bg-white-A700">
 
  
   
        <div className="flex flex-row justify-between pt-20">
          <div className="flex justify-center items-start pt-10  w-[30%] md:hidden">
            <div  className="flex flex-col w-[80%] ">
            <Text size="18xl" as="p">
                <ReactTyped strings={[`Welcome ${ userData?.firstname} ${ userData?.lastname}`]} typeSpeed={40} />
              </Text>
            
              <div className="flex flex-col items-start w-full mt-1.5">
              <div className="flex flex-col items-center  ">
                <div className="w-14 h-14 relative ">
                  <div className="flex justify-center items-center h-14 w-14  left-0 bottom-0 right-0 top-0  m-auto border-gray-300_03 border-2 border-solid absolute rounded-full">
                    <Text size="12xl" as="p" className="!text-gray-400_05 !font-hankengrotesk !font-normal">
                    {userData?.firstname ? userData.firstname.charAt(0) : ''}
                    </Text>
                  </div>
                  <button
                    className="bg-white-A700 rounded-full w-4 h-4 bottom-0 right-0 flex justify-center items-center  m-auto border-gray-400_10 border border-solid absolute "
                  >
                    <Img src="/images/img_plus.svg" />
                  </button>
                </div>
                <Text size="4xl" as="p" className="!font-hankengrotesk mt-2">
                  Add Photo
                </Text>
              </div>
              <div className="flex flex-col items-start w-[81%]  mt-5">
               
                <div className="flex justify-between w-[76%] md:w-full mt-[-3px] gap-5  flex-wrap">
                  <div>
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    First name
                  </Text>
                  <Text size="10xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.firstname}
                  </Text>
                  </div>
                  <div>
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    Last name
                  </Text>
                  <Text size="10xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.lastname}
                  </Text>
                  </div>
                  
                </div>
                <div className="flex flex-col items-start mt-[18px] gap-0.5">
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    Email Address
                  </Text>
                  <Text size="10xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.email}
                  </Text>
                </div>
                <div className="flex flex-col items-start mt-[17px]">
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    Company
                  </Text>
                  <Text size="10xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.companyName}
                  </Text>
                </div>
                <Text as="p" className="mt-[17px] !text-gray-400_09 !font-hankengrotesk">
                  Company
                </Text>
                <Text size="10xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                {userData?.position}
                </Text>
                <Text as="p" className="mt-[17px] !text-gray-400_09 !font-hankengrotesk">
                  Industry
                </Text>
                <Text size="10xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                {userData?.industry}
                </Text>
                <Text as="p" className="mt-[17px] !text-gray-400_09 !font-hankengrotesk">
                  Company Size
                </Text>
                <button
                  className="bg-green-50_f9 mt-2.5 sm:px-5 font-semibold px-4 py-2 rounded-xl"
                >
                  {userData?.companySize}
                </button>
              </div>
              <button
                  className="bg-green-A700_01 my-10 w-[80%] h-14 font-hankengrotesk flex flex-row justify-center items-center gap-2  font-semibold rounded-xl text-white-A700"
                >
                 <Img src="/images/img_edit.svg" alt="edit" />  Update Profile
                </button>
           
            </div>
            </div>
            
          
          </div>
          <div className="flex flex-col   gap-2 md:w-full pt-10 pb-10 w-[70%]">
          
            <div className="flex flex-col w-full gap-[15px] px-5">
            <div className="hidden md:block  w-full mb-3">
        <div className="flex items-center  border  rounded-xl bg-gray-200 border-gray-200 p-3 mt-5">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.275 9.55C7.63602 9.55 9.55 7.63602 9.55 5.275C9.55 2.91398 7.63602 1 5.275 1C2.91398 1 1 2.91398 1 5.275C1 7.63602 2.91398 9.55 5.275 9.55Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.0001 10L9.1001 9.10001" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            <input
                placeholder={'Search File'}
                className=" ml-2 flex-grow outline-none bg-transparent text-xs"
            />
            
        </div> 
        </div>
              <div className="flex flex-row sm:flex-col w-full justify-between gap-4  pb-10">
              <div className="flex flex-col items-start w-[40%] sm:w-full mb-0.5 gap-[5px]">
                <Text size="md" as="p" className="!text-blue_gray-900">
                  Your Overall Progress
                </Text>
                <div className="flex self-stretch justify-between items-start gap-5 mb-2">
                  <Button color="lime_100" size="sm" className="bg-lime-100 text-yellow-700 font-medium min-w-[105px] rounded-sm">
                  0% Completed
                  </Button>
                  <div className="flex items-center gap-2 invisible">
                    <Img src="/images/img_clock.svg" alt="clock_one" className="h-4 w-4" />
                    <Text  as="p" className="!font-medium text-base">
                      Finish before 04/07/2024
                    </Text>
                  </div>
                </div>
    
                <LinearProgressBar value={progressValue} max={maxProgress} />
              </div>

              <button
              onClick={handleAddCertificateToggle}
              className=" bg-black-900_01 w-52 sm:w-full self-end mb-0.5 font-hankengrotesk px-4 py-2 flex flex-row items-center justify-center gap-2 rounded-[10px] text-white-A700 text-base "
            >
            <Img src="/images/img_close_white_a700.svg" alt="close" />  Add Certifications
            </button>
              </div>
       <div className="grid  grid-cols-3 md:grid-cols-1 gap-4">
      <div className="col-span-1"> <div className="flex flex-col h-full items-center w-full gap-[37px] p-5 border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
                    <Text
                      size="5xl"
                      as="p"
                      className="self-start mt-[5px] !text-gray-900_01 !font-hankengrotesk !font-medium"
                    >
                      Sustainability Modules
                    </Text>
                    <Img src="/images/img_grid.svg" alt="grid_one" className="h-auto " />
                    <Link
                     to="/dashboard/module"
                     style={{ color: "#87CEFA", textDecoration: "none" }} className="flex items-center justify-center w-full">
                    <div className="flex items-center mb-[23px] gap-[7px]">
                      <Text size="5xl" as="p" className="text-[#10614E] !font-hankengrotesk !font-medium">
                        Launch Modules
                      </Text>
                      <Img
                        src="/images/img_arrow_right_green_900.svg"
                        alt="arrowright_one"
                        className="self-end h-[8px] mb-1"
                      />
                    </div>
                    </Link>
                    
                  </div>
        </div>
      <div className="col-span-1"><div className="flex flex-col h-full items-start p-5 border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
                   <div className="flex self-stretch justify-between items-center mt-2  gap-5 md:mr-0">
                   <Text  as="p" className="!font-hankengrotesk text-base sm:text-sm">
                      User Manager
                      </Text>
                      <div className="flex items-center mt-[3px] gap-1">
                        
                    <Button
                      onClick={handleComingSoon}
                      color="green_50_01"
                      size="sm"
                      className="bg-green-50_01 sm:px-2 font-hankengrotesk font-medium  rounded"
                    >
                      Coming soon
                    </Button>
                      </div>
                    </div>
                    <Img src="/images/img_lock.svg" alt="lock_one" className="self-center h-auto mt-9" />
                    <div className="flex self-stretch mt-3 ml-[5px] gap-[9px] md:ml-0">
                      <Button onClick={handleAddUserToggle} color="gray_300_05" className="bg-gray-200 w-full text-base sm:px-5 font-hankengrotesk rounded-[15px]">
                        Add a user
                      </Button>
                      <Button color="gray_300_05" className=" bg-gray-200 w-full text-base sm:px-5 font-hankengrotesk rounded-[15px]">
                        Edit a user
                      </Button>
                    </div>
                    <div className="flex self-stretch mt-3 ml-[5px] gap-[9px] md:ml-0">
                      <Button color="gray_300_05" className="bg-gray-200 w-full leading-3  text-base sm:px-5 font-hankengrotesk rounded-[15px]">
                      Reset Password
                      </Button>
                      <Button color="gray_300_05" className=" bg-gray-200 w-full leading-3 text-base sm:px-5 font-hankengrotesk rounded-[15px]">
                      Delete user
                      </Button>
                    </div>
               
                  </div></div>
      <div className="col-span-1">  <div className="flex flex-col items-center h-full  gap-[35px] p-5 border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
                
                  <div className="flex  self-stretch justify-between items-center mt-2  gap-5 md:mr-0">
                      <Text  as="p" className="!font-hankengrotesk text-base sm:text-sm">
                      Document Manager
                      </Text>
                      <div className="flex items-center mt-[3px] gap-1">
                        
                    {/* <Button
                      onClick={handleComingSoon}
                      color="green_50_01"
                      className="bg-green-50_01 text-sm sm:text-xs sm:px-2 font-hankengrotesk font-medium  rounded"
                    >
                      Coming soon
                    </Button> */}
                      </div>
                    </div>
                  <Img src="/images/img_file.svg" alt="file_one" className="h-auto" />
                  <Link 
                  to="/dashboard/doc-manager">
                   <div
                  style={{ color: "#87CEFA", textDecoration: "none" }} className="flex items-center justify-center w-full">
                    <div className="flex items-center mb-[23px] gap-[7px]">
                      <Text size="5xl" as="p" className="text-[#10614E]  !font-hankengrotesk !font-medium">
                      Launch Doc Manager
                      </Text>
                      <Img
                        src="/images/img_arrow_right_green_900.svg"
                        alt="arrowright_one"
                        className="self-end h-auto mb-1"
                      />
                    </div>
                    </div>
                  </Link>
              
                  {/* <div className="flex items-start mb-2 gap-1">
                    <Text size="5xl" as="p" className="!text-teal-800_01 !font-hankengrotesk">
                      Launch Doc Manager
                    </Text>
                    <Img src="/images/img_arrow_right_green_900.svg" alt="arrowright" className="h-2 mt-2" />
                  </div> */}
                </div></div>
      <div className="col-span-1"> <div className="flex flex-col h-full items-start  gap-[33px] p-5 border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
              
             
                    <div className="flex  self-stretch justify-between items-center mt-2  gap-5 md:mr-0">
                      <Text  as="p" className="!font-hankengrotesk text-base sm:text-sm">
                      Analytics
                      </Text>
                      <div className="flex items-center mt-[3px] gap-1">
                        
                    <Button
                      onClick={handleComingSoon}
                      color="green_50_01"
                      className="bg-green-50_01 text-sm sm:text-xs sm:px-2 font-hankengrotesk font-medium  rounded"
                    >
                      Coming soon
                    </Button>
                      </div>
                    </div>
                  <div className="flex flex-col self-center items-center justify-center mb-[21px] gap-[45px]">
                    <Img
                      src="/images/img_vector_teal_800_03.svg"
                      alt="vector_one"
                      className="h-auto "
                    />
                    <div className="flex items-center gap-1.5">
                      <Text size="5xl" as="p" className="!text-teal-800_01 !font-hankengrotesk">
                        Launch Analytics
                      </Text>
                      <Img src="/images/img_arrow_right_green_900.svg" alt="arrowright_five" className="h-[8px]" />
                    </div>
                  </div>
                </div></div>
      <div className="col-span-2 md:col-span-1"><div className="p-5 h-full border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
                  <div className="flex flex-col items-end mb-[26px]">
                    <Button
                      onClick={handleComingSoon}
                      color="green_50_01"
                      size="sm"
                      className="bg-green-50_01 sm:px-5 font-hankengrotesk font-medium min-w-[98px] rounded"
                    >
                      Coming soon
                    </Button>
                    <div className="flex sm:flex-col self-stretch justify-between items-start mt-5  gap-5 md:mr-0">
                    <Text  as="p" className="!font-hankengrotesk text-base sm:text-sm">
                        Click a person to see what they’re working on
                      </Text>
                      <div className="flex items-center mt-[3px] gap-1">
                        <a href="#">
                          <Text size="3xl" as="p" className="!text-teal-800_01 !font-hankengrotesk">
                            See All
                          </Text>
                        </a>
                        <Img
                          src="/images/img_arrow_right_blue_gray_700_01.svg"
                          alt="arrowright"
                          className="self-end h-[8px] mb-[3px]"
                        />
                      </div>
                    </div>
                    <div className="grid  grid-cols-7 md:grid-cols-4  my-10 md:gap-4 gap-10">
      
      <div className=""><Img
                        src="/images/img_ellipse_6.png"
                        alt="circleimage"
                        className="h-auto w-auto  rounded-[50%]"
                      /></div>
      <div className=""><Img
                        src="/images/img_ellipse_7.png"
                        alt="circleimage"
                        className=" h-auto w-auto rounded-[50%]"
                        
                      /></div>
                         <div className=""><Img
                        src="/images/img_ellipse_8.png"
                        alt="circleimage"
                        className="h-auto w-auto rounded-[50%]"
                      /></div>
      <div className=""><Img
                        src="/images/img_ellipse_9.png"
                        alt="circleimage"
                        className="h-auto w-auto rounded-[50%]"
                        
                      /></div>
                         <div className=""><Img
                        src="/images/img_ellipse_10.png"
                        alt="circleimage"
                        className="h-auto w-auto rounded-[50%]"
                      /></div>
      <div className=""><Img
                        src="/images/img_ellipse_11.png"
                        alt="circleimage"
                        className="h-auto w-auto rounded-[50%]"
                        
                      /></div>
                       <div className=""><Img
                        src="/images/img_ellipse_12.png"
                        alt="circleimage"
                        className="h-auto w-auto rounded-[50%]"
                        
                      /></div>
    </div>
               
                  </div>
                </div>
       </div>
       <div className="col-span-1"> <div className="flex flex-col h-full items-start  gap-1 p-5 border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
    
       <div className="flex  self-stretch justify-between items-center mt-2  gap-5 md:mr-0">
                      <Text  as="p" className="!font-hankengrotesk text-base sm:text-sm">
                      Hide Module
                      </Text>
                      <div className="flex items-center mt-[3px] gap-1">
                        
                    <Button
                      onClick={handleComingSoon}
                      color="green_50_01"
                      className="bg-green-50_01 text-sm sm:text-xs sm:px-2 font-hankengrotesk font-medium  rounded"
                    >
                      Coming soon
                    </Button>
                      </div>
                    </div>
                    <div className="flex self-stretch justify-between items-center mt-2  gap-5 md:mr-0">
                      <Text size="6xl" as="p" className="!font-hankengrotesk font-normal text-gray-600">
                      Culture
                      </Text>
                      <ThemeProvider theme={theme}>
            <div>
                <Switch  defaultChecked />
            </div>
        </ThemeProvider>
                    </div>
                    <div className="flex  self-stretch justify-between items-center mt-1  gap-5 md:mr-0">
                      <Text size="6xl" as="p" className="!font-hankengrotesk font-normal text-gray-600">
                      Biodiversity
                      </Text>
                      <ThemeProvider theme={theme}>
            <div>
            <Switch  defaultChecked />            </div>
        </ThemeProvider>
                    </div>
                    <div className="flex  self-stretch justify-between items-center mt-1  gap-5 md:mr-0">
                      <Text size="6xl" as="p" className="!font-hankengrotesk font-normal text-gray-600">
                      Air Emmisons
                      </Text>
                      <ThemeProvider theme={theme}>
            <div>
            <Switch  defaultChecked />
                        </div>
        </ThemeProvider>
                    </div>
                    <div className="flex  self-stretch justify-between items-center mt-1  gap-5 md:mr-0">
                      <Text size="6xl" as="p" className="!font-hankengrotesk font-normal text-gray-600">
                      Governance
                      </Text>
                      <ThemeProvider theme={theme}>
            <div>
          <Switch  defaultChecked />
              </div>
        </ThemeProvider>
                    </div>
                </div></div>
      <div className="col-span-2 md:col-span-1"><div className="md:self-stretch p-5  border-gray-400_7f border border-solid bg-white-A700 rounded-[10px]">
    
      <div className="flex  self-stretch justify-between items-center mt-2  gap-5 md:mr-0">
                      <Text  as="p" className="!font-hankengrotesk text-base sm:text-sm">
                      Contractor Audit request
                      </Text>
                      <div className="flex items-center mt-[3px] gap-1">
                        
                    <Button
                      onClick={handleComingSoon}
                      color="green_50_01"
                      className="bg-green-50_01 text-sm sm:text-xs sm:px-2 font-hankengrotesk font-medium  rounded"
                    >
                      Coming soon
                    </Button>
                      </div>
                    </div>
          <div className="flex items-center border rounded border-gray-300 p-3 mt-5">
            <input
                placeholder={'Add contractor’s email to request an audit'}
                className="flex-grow outline-none bg-transparent"
            />
             <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.50409 9.65628L19.3507 9.37297M4.36856 9.98109L1.57543 16.6726C1.32931 17.2621 1.20636 17.5568 1.26627 17.7351C1.31816 17.8897 1.44223 18.0092 1.59856 18.0553C1.77893 18.1086 2.06896 17.9749 2.64895 17.7071L19.1085 10.1104C19.6321 9.86876 19.8939 9.74793 19.9716 9.58447C20.039 9.44255 20.0359 9.27721 19.963 9.13794C19.8792 8.97763 19.613 8.86687 19.0806 8.64536L2.34318 1.68204C1.75339 1.43667 1.45851 1.31399 1.28029 1.37411C1.1258 1.42622 1.00639 1.55041 0.960396 1.70684C0.907331 1.88728 1.04156 2.17707 1.30987 2.7568L4.35627 9.33689C4.41071 9.45452 4.43793 9.51334 4.44943 9.57479C4.45966 9.6292 4.46065 9.68498 4.45257 9.7398C4.4435 9.80157 4.41847 9.86146 4.36856 9.98109Z" stroke="#494949" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
        </div> 
                </div>
       </div>
    </div>
             
       
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
       <SidebarMenu isMenuOpen={isMenuOpen} handleCloseMenu={handleCloseMenu} userData={userData} />
        )}
     {isAddUserOpen && (
      <AddUserSidebarMenu isMenuOpen={isAddUserOpen} handleCloseMenu={handleCloseAddUser} userData={userData} />

         
     )}
        {isAddCertificateOpen && (
      <AddCertificateSidebarMenu isMenuOpen={isAddCertificateOpen} handleCloseMenu={handleCloseAddCertificate} userData={userData} />

         
     )}
     <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </>
  );
}
