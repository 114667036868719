import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { CloseSVG } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { Input, Img, Text, Button, Heading } from "../../../components";
import { Box, LinearProgress, Switch, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DefaultLayout from "layout/DefaultLayout";
import { ReactTyped } from "react-typed";
import AuthService from "services/authService";
import { CircularProgressBar, LinearProgressBar } from "components/ProgressBar";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'; // Import your CSS file for styling
import Tour from 'reactour';

const theme = createTheme({
  palette: {
      primary: {
          main: '#4caf50', // Green color
      },
  },
});
export default function ModulePage() {
  const [searchBarValue8, setSearchBarValue8] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const handleBackPress = () => {
    navigate('/dashboard/home', { replace: true });
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackPress);
    return () => {
      window.removeEventListener('popstate', handleBackPress);
    };
  }, []);
  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const moduelitems = [
    { 
      id: 3, 
      sdg: 11,
      percentage: 0,
      status: 'Start now',
      img: '/images/img_qrcode.svg',
      name: 'Climate Change', 
      route:'/dashboard/question/climate-change',
      content: [
        'Analyse carbon footprint and emissions reduction initiatives',
        'Assess climate change adaptation  and mitigation strategies​',
        'Advise on climate-resilient practices'
      ]
    },
  
    { 
      id: 2, 
      sdg: 7,
      percentage: 0,
      status: 'Start now',
      img: '/images/img_thumbs_up_white_a700_44x38.svg',
      name: 'Waste Management', 
      route:'/dashboard/question/waste',
      content: [
        'Analyse waste generation and management practices​',
        'Ensure adherence to waste disposal regulations​',
        'Recommend waste reduction, recycling, and disposal solution'
      ]
    },
    { 
      id: 1, 
      sdg: 6,
      percentage: 0,
      img: '/images/img_television_white_a700.svg',
      name: 'Circularity Index Generator', 
      status: 'Start now',
      route:'/dashboard/question/cig/intro',
      content: [
        'Evaluate air quality and emissions, including greenhouse gases',
        'Identify sources of emissions and recommend reduction strategies​',
        'Assess compliance with air quality regulations'
      ]
    },
  
    
    { 
      id: 4, 
      sdg: 8,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_settings_white_a700.svg',
      name: 'Social Sustainability', 
      route:'',
      content: [
        'Assess social impact, diversity, and inclusivity​',
        'Review labour practices and employee well-being​​',
        'Suggest improvements for social responsibility and ethical conduct'
      ]
    },
   
    { 
      id: 6, 
      sdg: 11,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_settings_white_a700_43x29.svg',
      name: 'Culture', 
      route:'',
      content: [
        'Examine organisational culture and values​',
        'Evaluate alignment with sustainability objectives​​',
        'Suggest measures to cultivate a sustainable culture'
      ]
    },
    { 
      id: 5, 
      sdg: 9,
      percentage: 0,
      status: 'Start now',
      img: '/images/img_close.svg',
      name: 'Energy management systems', 
      route:'',
      content: [
        'Evaluate resource consumption and conservation efforts​​',
        'Analyse environmental policies and compliance with regulations',
        'Recommend eco-friendly practices for energy, water, and land use'
      ]
    },
    { 
      id: 7, 
      sdg: 9,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_television_white_a700_42x59.svg',
      name: 'Sustainable Communities', 
      route:'',
      content: [
        'Investigate community engagement and partnerships​​',
        'Evaluate social and economic contributions to local communities​​',
        'Advise on community-centric sustainability initiatives'
      ]
    },
    { 
      id: 8, 
      sdg: 5,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_settings_white_a700_38x44.svg',
      name: 'Health & Well-Being', 
      route:'',
      content: [
        'Review health and safety policies and practices​',
        'Assess employee well-being programs and mental health support​​',
        'Recommend measures to enhance health and well-being'
      ]
    },
    { 
      id: 9, 
      sdg: 7,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_television_white_a700_54x27.svg',
      name: 'Investment for Sustainability', 
      route:'',
      content: [
        'Examine organisational culture and values​',
        'Evaluate alignment with sustainability objectives​​​',
        'Suggest measures to cultivate a sustainable culture'
      ]
    },
    { 
      id: 10, 
      sdg: 6,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_car_green_600.svg',
      name: 'Education & Stakeholders', 
      route:'',
      content: [
        'Evaluate sustainability education and awareness programs​​',
        'Analyse stakeholder engagement & communication',
        'Suggest methods to enhance education and stakeholder relations'
      ]
    },
    { 
      id: 11, 
      sdg: 11,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_thumbs_up_white_a700_44x52.svg',
      name: 'Biodiversity', 
      route:'',
      content: [
        'Assess impacts on local ecosystems and biodiversity​​',
        'Recommend conservation and biodiversity protection measures​​​​',
        'Ensure compliance with wildlife &  habitat protection regulations'
      ]
    },
    { 
      id: 12, 
      sdg: 6,
      percentage: 0,
      status: 'Coming soon',
      img: '/images/img_thumbs_up_white_a700_48x47.svg',
      name: 'Governance', 
      route:'',
      content: [
        'Examine governance structures and ethical decision-making​​',
        'Analyse transparency, accountability, and compliance with regulations',
        'Provide guidance for strengthening governance for sustainability'
      ]
    },
    // Add more items as needed
  ];

  const userData = AuthService.getUserData();
  // Access the firstname and lastname properties directly from the userData object
  const { firstname, lastname } = userData;

  const [progressValue, setProgressValue] = useState(0);
  const maxProgress = 100;
  const targetProgress = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressValue < targetProgress) {
        setProgressValue(progressValue + 1);
      } else {
        clearInterval(interval);
      }
    }, 100); // Change the interval time as needed
    return () => clearInterval(interval);
  }, [progressValue, targetProgress]);

  const [textSize, setTextSize] = useState('big');

  useEffect(() => {
    setTimeout(() => {
      setTextSize('small');
    }, 1000); // Adjust the delay as needed
    setTimeout(() => {
      setTextSize('medium');
    }, 1500); // Adjust the delay as needed
  }, []);

  const navigate = useNavigate()
  const [introcomplete, setintroComplete] = useState(true);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    // Check local storage for intro completion
    const userDataa = AuthService.getUserData();
    const { _id, } = userDataa;
    const userData = localStorage.getItem(`introComplete${_id}`);
    if (userData) {
      setintroComplete(true);
    } else {
      setIsTourOpen(true); // Start the tour if it's the first time
      setintroComplete(false);
    }
  }, []);

  const closeTour = () => {
    setIsTourOpen(false);
    // Update intro completion status in local storage
    const userDataa = AuthService.getUserData();
    const { _id, } = userDataa;
    localStorage.setItem(`introComplete${_id}`, 'true'); // Convert to string
    setintroComplete(true);
  };




  const steps = [
    {
      selector: '.first-div',
      content: 'Click the start now button to  answer questions relating to  your industry',
    },
    {
      selector: '.second-div',
      content: 'This is an overview of your progress and how much time you’ve got to complete your assessment.',
    },
  ];
  return (
    <>
     <Helmet>
        <title>SALI | Module</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="w-full p-4 md:p-6 2xl:p-10 ">
      <svg  onClick={handleBackPress}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left cursor-pointer hidden md:block"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>

      <div className={` justify-between flex flex-row md:flex-col my-5 md:my-5 md:gap-2 '}`}>

      {introcomplete ? (  
   <h1 className='text-[24px]  font-medium font-Kumbh'>
        
   <ReactTyped 
             strings={[
                 `Hello ${userData?.firstname} ${userData?.lastname}`,
                 "Let's Begin",
             ]} 
             typeSpeed={160} 
             backSpeed={180}
             className='font-Kumbh font-medium text-[24px]'
         />
</h1>
      ) : (
        <div className={`bg-gradient-to-r from-[#0F2F53] to-[#E27A3F] bg-clip-text text-transparent ${textSize === 'big' ? 'text-4xl font-semibold' : 'text-2xl '} transition-all duration-500 ease-in-out`}>
        Select your module</div>
      )}
        <div  className= {`second-div flex flex-col    mb-0.5 gap-[5px]   ${textSize === 'big' ? 'items-start justify-end  w-[40%] md:w-full' : '  items-start justify-end w-[40%] md:w-full flex-row md:my-5 md:gap-2 md:flex-col'}`}>
                <p className='text-sm font-medium text-[#7A7A7A] font-Kumbh'>Your Overall Progress</p>
                <div className="flex self-stretch justify-between items-start gap-5">
                  <Button color="lime_100" size="sm" className="bg-lime-100 text-yellow-700 font-medium min-w-[105px] rounded-sm">
                  {progressValue}% Completed
                  </Button>
                  <div className="flex items-center gap-2">
                    <Img src="/images/img_clock.svg" alt="clock_one" className="h-4 w-4" />
                    <Text  as="p" className="!font-medium text-base">
                      Complete in 2 months
                    </Text>
                  </div>
                </div>
                 <LinearProgressBar value={progressValue} max={maxProgress} />
              </div>
    </div>
      {/* <div className="flex flex-row sm:flex-col w-full justify-between gap-4  pb-10">
      {introcomplete ? (  
   <h1 className='text-[24px] xl:w-[45%] font-medium font-Kumbh'>
        
   <ReactTyped 
             strings={[
                 `Hello ${userData?.firstname}`,
                 "Let's Begin",
             ]} 
             typeSpeed={160} 
             backSpeed={180}
             className='font-Kumbh font-medium text-[24px]'
         />
</h1>
      ) : (
        <div className="container">
        <div className={`text ${textSize}`}>
          Please select your primary area of focus for 
          sustainability compliance assessment</div>
      </div>
      )}
         
     
              <div className="flex flex-col items-start w-[40%] sm:w-full mb-0.5 gap-[5px]">
                <p className='text-sm font-medium text-[#7A7A7A] font-Kumbh'>Your Overall Progress</p>
                <div className="flex self-stretch justify-between items-start gap-5">
                  <Button color="lime_100" size="sm" className="bg-lime-100 text-yellow-700 font-medium min-w-[105px] rounded-sm">
                  {progressValue}% Completed
                  </Button>
                  <div className="flex items-center gap-2">
                    <Img src="/images/img_clock.svg" alt="clock_one" className="h-4 w-4" />
                    <Text  as="p" className="!font-medium text-base">
                      Complete in 2 months
                    </Text>
                  </div>
                </div>
                 <LinearProgressBar value={progressValue} max={maxProgress} />
              </div>

        
            </div> */}
  <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-4 w-full md:[95%]">
      {moduelitems.map(item => (
        <div key={item.id} className="first-div transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-115 duration-300 w-full p-4 mb-5 bg-white-A700 border border-gray-300 rounded-xl" >
         <div className='flex flex-col gap-4 '>
                    <div className='flex items-center justify-between'>
                        <div className='flex flex-col gap-[11px]'>
                            <h3 className='text-md font-medium text-[#272727]'>{item.name}</h3>
    {introcomplete ? (  
     <button
     className={`px-[8px] flex w-[90px] border justify-center items-center h-[30px] rounded-[4px] border-[#7ADDA2]  text-[11px] font-Kumbh font-semibold  ${
      item.status === 'Start now' ? 'bg-green-400 text-white-A700' : 'bg-white-A700 text-green-A700_01'
    }`}
    onClick={() => {
      if (item.status === 'Start now') {
        navigate(`${item.route}`,);
        
      } else {
        toast.warn('This feature is coming soon!', {
          position:"top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
      }
    }}
    >
    {item.status}
    </button>
      ) : (
        <button
        className={`px-[8px] flex w-[90px] border justify-center items-center h-[30px] rounded-[4px] border-[#7ADDA2]  text-[11px] font-Kumbh font-semibold  ${
         item.status === 'Continue' ? 'bg-green-400 text-white-A700' : 'bg-white-A700 text-green-400'
       }`}
       onClick={() => {
         if (item.status === 'Start now') {
            navigate("/dashboard/question/climate-change");
         } else {
           toast.warn('This feature is coming soon!', {
             position:"top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
             transition: Bounce,
             });
         }
       }}
       >
      {item.status}

       </button>
      )}
                       
                        </div>
                        <Img src={item.img} alt="air_emmissions" className="h-[49px] ml-2 md:ml-0" />
                    </div>
                    <div className='flex justify-between mt-5'>
                    <div className="w-16 h-16 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300">
        <CircularProgressbar
          value={item.percentage}
          text={`${item.percentage}%`}
          styles={buildStyles({
            strokeLinecap: 'round', // Make progress round
            pathColor: '#28a745', // Green progress color
            trailColor: '#ccc' // Gray background color
          })}
        />
      </div>         <button className='hidden px-4  w-32 justify-center items-center h-16 rounded-2xl border-[#7ADDA2] bg-[#E5FCED] text-[#5B9C80] text-3xl font-Kumbh font-medium'>
                        {item.sdg}SDGs
                        </button>
                    </div>
                </div>
        </div>
      ))}
    </div>
      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        onAfterOpen={() => {
          console.log('Tour is opened');
        }}
        onClose={closeTour}
      />
      </DefaultLayout>
    </>
  );
}
