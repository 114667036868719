import React, { useState, useEffect, useRef } from 'react';
import AuthService from 'services/authService';
import ProgressBar from '@ramonak/react-progress-bar';
import Autocomplete from '@mui/material/Autocomplete';
import { Input, Img, Text, Button, Heading, TextArea } from "../../../../components";
import DefaultLayout from 'layout/DefaultLayout';
import { CgSpinner } from 'react-icons/cg';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import axios from 'axios';
import MyDoughnutChart from '../component/chart';
import { TextField } from '@mui/material';
import ESGEngagement from '../component/ESGEngagement';
import CustomProgressBar from '../component/progressBar';
import { useNavigate } from 'react-router-dom';

interface SWOT {
  Strengths: string;
  Weaknesses: string;
  Opportunities: string;
  Threats: string;
}

 interface ClimateData {
  generalScore: string;
  evidenceScore: string;
  totalScore: string;
  certScore: string;
  SWOT: SWOT;
}

interface Option {
  text: string;
  evd: string;
  tips: string;
  evdText: string;
  keyWord: string;
  point: number;
  _id: string;
}

interface Question {
  _id: string;
  sector: string;
  subSector: string;
  type: string;
  industryFunction: string;
  userFunction: string;
  selectAssessmentCat: string;
  selectComplianceCat: string;
  text: string;
  tips: string;
  point: number;
  options: Option[];
  jurisdiction: string;
  scheme: string;
  body: string;
  tsi: string;
  ntsn: string;
  __v: number;
}


// interface Option {
//   _id: string;
//   text: string;
//   evd?: string;
//   evdText?: string;
//   tips?: string;
//   point?: number;
//   nextQuestion?: string;
// }

// interface Question {
//   _id: string;
//   sector: string;
//   subSector: string;
//   type: string;
//   industryFuntion: string;
//   userFunction: string;
//   selectAssessmentCat: string;
//   selectComplianceCat: string;
//   text: string;
//   tips: string;
//   options: Option[];
// }

interface SearchDoc {
  name: string;
  type: string;
  category: string;
  filePart: string;
  evd: string;
  link: string;
  point?: number;
  questionIndex: number;
  verify: boolean;
  result?: any;
}
const userList = [
  'Hanotu Weli',
  'Richard Ekemini',
  'Teria ',
  'Fajili Bibi',
  'Darren Gee',
  'Ashley Zee',
  'Olivia Martinez',
  'William Anderson',
  'Sophia Taylor',
  'James Thompson'
];
interface Cert {
  certName: string;
  link: string;
  size: string;
  name: string;
  date: string;
  access: string;
}

interface UserDatas {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  cert: Cert[];
}
const files = [
  { name: "Scissors Remfg Recycling Data Q3",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf", date: "Feb 13, 2024", access: "3 Members", size: "17 mb", type: "video" },
  { name: "Reuse Optimization Internal Report",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Sterilization Improvement Compliance Monitoring",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Remanufacturing Partnership Pilot Program Proposal",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Recycling Facility Material Recovery Logs",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  
  { name: "Waste Audit Segregation Gaps Analysis",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Waste Separation Improvement Audit Result",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Recycling Vendor Partnership Agreement",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Carbon Footprint LCA_Scissors Recycling Remanufacturing",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  
  { name: "Remanufacturing Impact Internal Study",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Carbon Reduction Progress Report Q2",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Remanufacturing Low Carbon Procurement Targets",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },

];

const WasteQuestions: React.FC = () => {
  const userData = AuthService.getUserData();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<{ name: string, evd: string,evdTxt: string,tips:string,point: number ,currentQuestionIndex:any}[][]>([]);
  const [certs, setCerts] = useState<{ certName: string ,link: string}[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [curentAnswername, setCurrentAnswername] = useState<string>('');
  const [curentAnswerevd, setCurrentAnswerevd] = useState<string>('');
  const [curentAnswerque, setCurrentAnswerque] = useState<string>('');

  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  const [searchDocs, setSearchDocs] = useState<SearchDoc[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [issaved, setSaved] = useState(false); 
  const [isFinish, setFinished] = useState(false); 
  const [finalResult, setFinalResult] = useState<any[]>([]);
  const [finalResultloading, setFinalResultLoading] = useState(false);
  const [finalResultReady, setFinalResultReady] = useState(false);
  const navigate = useNavigate();

  // const [sendResultResponse, setSendResultResponse] = useState<any | null>(null); 

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await AuthService.fetchCerts();
  //       setUserData(data);
  //     } catch (error) {
  //       // setError(error as Error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dizlp3hvp/upload';
  const uploadPreset = 'efgps0l1';
  useEffect(() => {
    const fetchData = async () => {
      try {
      
        const questionsData = await AuthService.fetchWasteQuestions();
        console.log(userData)
        setQuestions(questionsData);
        setAnswers(new Array(questionsData.length).fill([]));
        console.log('question', questionsData)

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questions.length - 1));
    setSelectedAnswer('');
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setSelectedAnswer('');
  };



  const handleAnswerChange = (name: string, evd: string, evdTxt: string, tips:string, point: number, currentQuestionIndex:any) => {
    const updatedAnswers = [...answers];
    const currentAnswers = updatedAnswers[currentQuestionIndex];

    if (currentAnswers.some(ans => ans.name === name)) {
      updatedAnswers[currentQuestionIndex] = currentAnswers.filter((ans) => ans.name !== name);
    } else {
      updatedAnswers[currentQuestionIndex] = [...currentAnswers, { name, evd,evdTxt,tips,point,currentQuestionIndex }];
    }

    setAnswers(updatedAnswers);
  };


  const handleSelectedAnswer = (selectedAnswer: string , selectedEvd: string) => {
    setSelectedAnswer(selectedAnswer);
    setCurrentAnswername(selectedAnswer);
    setCurrentAnswerevd(selectedEvd);
    setShowAutocomplete(true);
  };


  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newSearchDocs: SearchDoc[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'rztljgso');
        try {
          const uploadResponse = await axios.post(`https://api.cloudinary.com/v1_1/dizlp3hvp/upload`, formData);
          const data = uploadResponse.data;
       
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
      setSearchDocs([...searchDocs, ...newSearchDocs]);
    }
  };

  
  
  const handleSubmit = async () => {
    handleAnalysisToggle();
    const updatedSearchDocs = [...searchDocs];
    const resultArray: any[] = [];
    const maxRetries = 5;
    let retryCount = 0;
  
    const processDocuments = async () => {
      try {
        for (const doc of updatedSearchDocs) {
          const bodyContent = doc.type === 'search'
            ? {
                defEvidence: doc.evd,
                filePath: doc.link
              }
            : {
                question: questions[currentQuestionIndex].text,
                filePath: doc.filePart,
              };
  
          console.info('defEvidence', doc.link);
          console.info('filePath', doc.filePart);
          console.info('type', doc.type);
  
          const url = doc.type === 'search'
            ? 'https://saudit-jheg.onrender.com/document/ismatch-docx/'
            : 'https://saudit-jheg.onrender.com/document/validate-docx/';
  
          const response = await axios.post(url, bodyContent, {
            headers: {
              Authorization: `Bearer ${AuthService.getToken()}`,
            }
          });
  
          const data = await response.data;
          doc.result = data.score;
  
          if (data.result !== 'is not Matched' && data.result !== 'Bad') {
            const questionText = questions[currentQuestionIndex]?.text || '';
            const answer = answers[currentQuestionIndex].find(a => a.name === doc.category);
            if (answer) {
              resultArray.push({
                question: { text: questionText },
                answer: { text: answer.name, evd: data.scorePercentage, point: doc.point }
              });
            }
          }
  
          console.log('Response', data);
        }
  
        setFinalResult(resultArray);
        setSearchDocs(updatedSearchDocs);
        handlAnalysisCloseMenu();
  
      } catch (error) {
        if (error.response && error.response.status === 500 && retryCount < maxRetries) {
          retryCount++;
          console.error(`Error 500 occurred, retrying (${retryCount}/${maxRetries}):`, error);
          setTimeout(processDocuments, 1000);  // Retry after 1 second
        } else {
          handlAnalysisCloseMenu();
          console.error('Error submitting documents:', error);
        }
      }
    };
  
    await processDocuments();
  };
  

  const handleAutocompleteChange = (event, value, certLink:string) => {
    if (value) {
      const newSearchDoc: SearchDoc = {
        name: value,
        type: 'search',
        category: selectedAnswer,
        evd: curentAnswerevd,
        link: certLink,
        filePart: 'value',
        questionIndex: currentQuestionIndex,
        verify: false
      };
      setSearchDocs([...searchDocs, newSearchDoc]);
    }
  };



  
  const currentQuestion = questions[currentQuestionIndex];
  const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

  const groupedSearchDocs = searchDocs.reduce((acc: { [key: string]: { [key: number]: SearchDoc[] } }, doc) => {
    if (!acc[doc.category]) {
      acc[doc.category] = {};
    }
    if (!acc[doc.category][doc.questionIndex]) {
      acc[doc.category][doc.questionIndex] = [];
    }
    acc[doc.category][doc.questionIndex].push(doc);
    return acc;
  }, {});



  const [isAddUserOpen, setAddUserOpen] = useState(false);
  const handleAddUserToggle = () => {
    setAddUserOpen(!isAddUserOpen);
    disableBodyScroll();
  };

  const handleCloseAddUser = () => {
    setAddUserOpen(false);
    enableBodyScroll();
  };


  // Function to disable body scrolling
  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  // Function to enable body scrolling
  const enableBodyScroll = () => {
    document.body.style.overflow = '';
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const asignusermoveToNextQuestion = () => { 
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
  });
  
    toast.success('Email sent successfully.', {
      position:"top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
    handleassignCloseMenu();
    // handleNavigate();
  };
  const handleCloseAll = () => {
    toast.success('invite sent successfully', {
      position:"top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
  
     
    };
    const [dialogType, setDialogType] = useState('');
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isAnalysisOpen, setAnalysisOpen] = useState(false);

    const navbarRef = useRef(null);
    
    const handleMenuToggle =(type: string) => {
      setDialogType(type);
      setMenuOpen(!isMenuOpen);
      disableBodyScroll();
    };
    const handleEditMenuToggle =(type: string, index:number) => {
      setDialogType(type);
      setMenuOpen(!isMenuOpen);
      disableBodyScroll();
      setCurrentQuestionIndex(index);
    };
    const handleassignCloseMenu = () => {
      setMenuOpen(false);
      enableBodyScroll();
    };

      
    const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSearchQuery(query);
      if (query.trim() === '') {
        setSearchResults([]);
      } else {
        const results = userList.filter(user => user.toLowerCase().includes(query.toLowerCase()));
        setSearchResults(results);
      }
    };
  
    const handleUserClick = (user: string) => {
      setSelectedUser(user);
      setSearchQuery(user);
      setSearchResults([]);
    };


    const handlenewCloseMenu = () => {
      setMenuOpen(false);
      enableBodyScroll();
    };


    const handleAnalysisToggle =() => {
      handlenewCloseMenu();
      toast.warn('Analysing Document', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    };

    const handlAnalysisCloseMenu = () => {
    
      enableBodyScroll();
    };
    const handlPreviewSection = () => {
      setAnalysisOpen(true);
    };
    const handlPreviewClose = () => {
      setAnalysisOpen(false);
    };
    const [progressPercentage, setProgress] = useState(0);
    
       const handleSendResult = async () => {
      // const maxRetries = 7;
      // let retryCount = 0;
    
      // const sendResult = async () => {
      //   try {
      //     setFinalResultLoading(true);
    
      //     const response = await axios.post('https://saudit-jheg.onrender.com/document/rankAndSWOT/', {
      //       response: finalResult
      //     }, {
      //       headers: {
      //         Authorization: `Bearer ${AuthService.getToken()}`,
      //       }
      //     });
    
      //     console.log('Full response:', response);
      //     if (response.data) {
      //       const progress = response.data.progress || 100; // Assuming response.data.progress contains the progress percentage
      //       setProgress(progress); // Update progress based on response
      //       console.log('Response data:', response.data);
      //       setSendResultResponse(response.data);
      //       setFinalResultLoading(false);
      //       setFinalResultReady(true);
      //       console.log('Response sent successfully:', response.data);
      //     } else {
      //       console.error('No data in response:', response);
      //       setFinalResultLoading(false);
      //       setProgress(0); // Reset progress if no data in response
      //     }
      //   } catch (error) {
      //     if (error.response && error.response.status === 500 && retryCount < maxRetries) {
      //       retryCount++;
      //       console.error(`Error 500 occurred, retrying (${retryCount}/${maxRetries}):`, error);
      //       setTimeout(sendResult, 1000);  // Retry after 1 second
      //     } else {
      //       console.error('Error sending result:', error.response ? error.response.data : error.message);
      //       setFinalResultLoading(false);
      //       setProgress(0); // Reset progress if an error other than 500 occurs or retries are exhausted
      //     }
      //   } finally {
      //     if (retryCount >= maxRetries) {
      //       setFinalResultLoading(false);
      //       setProgress(0); // Reset progress if maximum retries are reached
      //     }
      //   }
      // };
     
      navigate('/dashboard/question/waste/report', { replace: true });
      // await sendResult();
    };
    
    
    const sendResultResponse: ClimateData = {
      generalScore: "40%",
      evidenceScore: "40%",
      totalScore: "80%",
      certScore:"20%",
      SWOT: {
        Strengths: "Your company's climate-related governance structure is acknowledged by the board, which indicates a level of awareness and top-level support. This is a critical foundation for driving sustainability initiatives effectively throughout the organization.",
        Weaknesses: "The integration of climate-related governance into your company operations is currently lacking. Without fully integrated governance, it can be challenging to implement systematic and cohesive sustainability strategies. Additionally, the provided evidence is minimal, which suggests that documentation and verifiable actions supporting your claims are currently insufficient.",
        Opportunities: "There is significant potential for improvement by aiming to fully integrate climate-related governance within your company. This can be achieved through comprehensive sustainability policies, establishing dedicated teams, and integrating sustainability KPIs across various departments. Increasing the documentation and evidence of your sustainability efforts will also bolster credibility and demonstrate tangible progress.",
        Threats: "Insufficient integration of climate-related governance might expose your company to risks such as regulatory non-compliance, reputational damage, and potential financial losses due to inefficient resource use and missed opportunities for sustainability-driven innovation. Additionally, without strong evidence to back up your claims, stakeholders might question the authenticity of your sustainability efforts."
      }
    };
  
    // useEffect(() => {
    //   console.log('Updated sendResultResponse:', sendResultResponse);
    //   setFinalResultReady(true);
    // }, [sendResultResponse]);
    
  const handleRemoveDoc = (filePart: string) => {
    setSearchDocs(prevDocs => prevDocs.filter(doc => doc.filePart !== filePart));
  };

  return (

    <>
        <>   
     <Helmet>
        <title>SALI | Waste Management</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   
      <div className="w-full  bg-white-A700">
    
      {finalResultloading?( 
        
        <div   className="bg-white-A700 p-5 md:p-1 flex flex-col w-full h-screen items-center  justify-center dialog-inner   rounded-lg shadow-lg  ">
        
        <svg className='bg-opacity-10 animate-pulse my-10' width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M54.807 35.2125C56.8434 36.5068 59.1307 37.3612 61.5207 37.7203V48H66.6734V37.7203C68.6133 37.3945 70.4851 36.7506 72.2126 35.815C73.8401 34.9443 75.2611 33.7374 76.3803 32.2755C78.0688 29.9881 78.9841 27.2276 78.9945 24.3906C79.0756 20.7796 78.2583 17.2046 76.6152 13.9829C73.9453 8.75327 70.1532 4.16961 65.5065 0.555339C65.0724 0.179745 64.5112 -0.0182439 63.936 0.00132362C63.3608 0.0208912 62.8145 0.256558 62.4073 0.660771L61.1267 1.813C60.1871 2.6414 59.2247 3.49239 58.3003 4.41869C55.305 7.36246 52.869 10.8193 51.1092 14.6231C49.2457 18.3907 48.5949 22.6373 49.2451 26.7855C49.5137 28.5031 50.149 30.1438 51.1083 31.5973C52.0677 33.0508 53.3288 34.2834 54.807 35.2125ZM61.5207 18.6446V32.5918C61.1722 32.524 60.8691 32.4487 60.5887 32.3659C59.2014 31.9964 57.9097 31.3365 56.7999 30.4304C55.3799 29.2384 54.4585 27.5627 54.216 25.7311C53.7918 22.7305 54.2717 19.6723 55.5951 16.9426C57.447 12.9809 60.0835 9.43097 63.3469 6.50475C63.5515 6.30894 63.7561 6.12821 63.9834 5.92487C68.0677 9.62254 70.8032 13.26 72.546 17.3492C73.5706 19.6435 74.0254 22.1488 73.8721 24.6542C73.5993 28.382 71.7125 30.8371 68.0904 32.1399C67.6148 32.3126 67.1289 32.456 66.6356 32.5692V18.6747L61.5207 18.6446Z" fill="#5BBA47"/>
        <path d="M79.2388 36.9713C79.7485 39.2723 80.7364 41.4409 82.1386 43.3368L75 50.4702L78.5656 54L85.682 46.8887C87.255 48.0059 89.0057 48.8497 90.8603 49.3847C92.5879 49.9028 94.4069 50.0438 96.194 49.7982C98.9523 49.3724 101.502 48.0789 103.473 46.106C106.039 43.6577 107.958 40.6133 109.058 37.2445C110.845 31.7715 111.408 25.9739 110.708 20.2602C110.669 19.6999 110.417 19.1755 110.005 18.793C109.593 18.4105 109.051 18.1985 108.489 18.1999L106.676 18.1334C105.478 18.067 104.257 18.0079 102.978 18.0005C98.8644 17.978 94.7813 18.7011 90.9269 20.1346C87.0262 21.4719 83.6358 23.9819 81.2213 27.3198C80.2242 28.6962 79.5317 30.2686 79.1899 31.9326C78.8481 33.5967 78.8648 35.3142 79.2388 36.9713ZM98.9015 33.6631L95.358 30.1333L85.6968 39.7848C85.5119 39.4968 85.3492 39.2384 85.2086 38.9799C84.5117 37.7731 84.0828 36.4308 83.951 35.044C83.7994 33.2377 84.3269 31.4397 85.4305 30.0003C87.2216 27.621 89.6767 25.8228 92.4878 24.8312C96.12 23.4207 100.122 22.83 105.064 22.926L105.9 22.9629C106.151 28.3388 105.515 32.74 103.88 36.7867C102.998 39.0845 101.575 41.1363 99.73 42.7682C96.9485 45.1681 93.9451 45.5595 90.5422 43.9792C90.0913 43.7642 89.6562 43.5174 89.2403 43.2408L98.9015 33.6631Z" fill="#5BBA47"/>
        <path d="M128.314 62.3681L127.162 61.0853C126.409 60.2047 125.506 59.2103 124.558 58.2614C121.613 55.2644 118.155 52.829 114.351 51.0728C110.58 49.2241 106.336 48.5907 102.196 49.2585C100.481 49.5287 98.8432 50.1639 97.391 51.122C95.9387 52.0801 94.7054 53.339 93.7731 54.8151C92.4849 56.8571 91.6338 59.1479 91.2742 61.5407H81V66.6722H91.3043C91.6291 68.6168 92.2754 70.4925 93.2161 72.2213C94.0855 73.8501 95.292 75.2713 96.7537 76.3887C99.0405 78.078 101.8 78.9923 104.634 79H105.282C108.683 78.9711 112.032 78.1501 115.067 76.6012C120.292 73.9205 124.87 70.1166 128.479 65.4577C128.843 65.0163 129.027 64.453 128.997 63.8802C128.966 63.3074 128.722 62.7674 128.314 62.3681ZM110.31 66.6646V61.5256H96.3999C96.4752 61.1688 96.543 60.8651 96.6257 60.5843C96.9883 59.192 97.6486 57.8965 98.5601 56.7888C99.7461 55.3622 101.419 54.4358 103.249 54.1927C106.247 53.7658 109.303 54.2411 112.033 55.559C116 57.4096 119.554 60.0513 122.48 63.3246L123.06 63.9547C119.364 68.0538 115.729 70.7866 111.649 72.5401C109.356 73.5668 106.853 74.0249 104.348 73.8761C100.585 73.6104 98.1687 71.7202 96.8214 68.0993C96.6478 67.6232 96.5045 67.1364 96.3924 66.6419L110.31 66.6646Z" fill="#5BBA47"/>
        <path d="M101.681 81.269C100.307 80.2636 98.7346 79.5633 97.0697 79.2145C95.4048 78.8657 93.685 78.8764 92.0245 79.246C89.7231 79.7575 87.5547 80.7498 85.6606 82.1582L78.7504 75.2223L78.5289 75L75 78.5495L82.1391 85.6928C81.025 87.2701 80.1816 89.0234 79.6437 90.8799C79.1332 92.6119 78.9948 94.433 79.2377 96.2226C79.6673 98.9843 80.9599 101.538 82.929 103.514C85.379 106.08 88.4221 107.999 91.7883 109.101C95.6452 110.395 99.6894 111.036 103.756 110.998C105.372 110.998 107.034 110.91 108.754 110.739C109.313 110.698 109.835 110.446 110.216 110.033C110.597 109.621 110.808 109.078 110.806 108.516L110.873 107.249V106.827C110.939 105.678 111.013 104.374 110.998 103.062C111.022 98.9419 110.299 94.8516 108.864 90.9911C107.524 87.0855 105.015 83.6903 101.681 81.269ZM89.2191 85.7151C89.507 85.5224 89.7654 85.3594 90.0238 85.2186C91.2299 84.5194 92.5721 84.0897 93.9588 83.9589C95.7651 83.8027 97.5641 84.3314 99.0012 85.4409C101.38 87.2321 103.178 89.689 104.169 92.5028C105.641 96.5308 106.291 100.815 106.081 105.1C106.081 105.382 106.081 105.656 106.044 105.937C100.662 106.197 96.2696 105.559 92.2239 103.929C89.9266 103.046 87.8754 101.62 86.2439 99.7721C83.8371 96.9933 83.4384 93.9848 85.0183 90.5687C85.2365 90.1186 85.4831 89.683 85.7566 89.2645L95.1696 98.6902L95.391 98.9199L98.9274 95.3631L89.2191 85.7151Z" fill="#5BBA47"/>
        <path d="M74.1873 93.7724C72.1442 92.4861 69.8534 91.6371 67.4612 91.2797V81H62.3123V91.2872C60.3695 91.6111 58.4956 92.2578 56.7691 93.2C55.1421 94.0733 53.7225 95.2828 52.6061 96.7471C50.9198 99.0353 50.009 101.796 50.0051 104.632C49.9269 108.245 50.7528 111.821 52.4089 115.04C55.0895 120.262 58.8891 124.837 63.5408 128.445C63.9765 128.821 64.5403 129.019 65.1178 128.998C65.6952 128.978 66.2429 128.739 66.6498 128.332L67.6583 127.42L67.9692 127.142C68.8944 126.321 69.8498 125.477 70.7446 124.566C73.7407 121.619 76.1737 118.156 77.9257 114.347C79.7716 110.576 80.4068 106.333 79.7457 102.192C79.4743 100.477 78.8383 98.8392 77.8799 97.3874C76.9215 95.9357 75.6627 94.7033 74.1873 93.7724ZM62.3426 96.4383V110.333H67.4763V96.4157C67.8252 96.4835 68.1285 96.5513 68.4242 96.6341C69.811 97.0075 71.1029 97.667 72.2157 98.5696C73.6419 99.7563 74.5697 101.43 74.8167 103.261C75.2427 106.26 74.7678 109.318 73.4518 112.05C71.5885 116.028 68.9344 119.592 65.6488 122.525L65.0119 123.098C60.917 119.408 58.1871 115.778 56.4279 111.696C55.3986 109.403 54.9383 106.898 55.0857 104.391C55.3511 100.626 57.2393 98.2081 60.8564 96.8601C61.3424 96.6887 61.8388 96.5479 62.3426 96.4383Z" fill="#5BBA47"/>
        <path d="M49.7733 92.0165C49.2614 89.7201 48.2685 87.5565 46.8591 85.6666L54 78.5433L50.4481 75L43.3369 82.1528C41.7568 81.0457 40.0028 80.2068 38.1462 79.6703C36.4134 79.1584 34.59 79.0228 32.7998 79.2725C30.0383 79.7012 27.4856 80.9911 25.5106 82.9557C22.9445 85.4002 21.0264 88.4368 19.9269 91.7955C18.1423 97.2566 17.5879 103.041 18.303 108.738C18.3425 109.297 18.5941 109.82 19.0071 110.202C19.4201 110.583 19.9636 110.795 20.5276 110.793L22.2182 110.875C23.3528 110.933 24.5244 111 25.7108 111H25.9852C30.1065 111.016 34.1966 110.289 38.0572 108.856C41.9686 107.505 45.3658 104.99 47.786 101.652C48.7875 100.278 49.4827 98.7084 49.8254 97.0469C50.168 95.3855 50.1503 93.6706 49.7733 92.0165ZM30.3009 95.1251L30.0784 95.3535L33.6303 98.8672L43.2701 89.2246C43.4555 89.5045 43.6187 89.7623 43.767 90.0202C44.4612 91.226 44.8908 92.564 45.0275 93.9465C45.1838 95.7488 44.6547 97.5439 43.5445 98.9777C41.7563 101.352 39.2997 103.146 36.4852 104.134C32.4568 105.613 28.1688 106.267 23.8792 106.057L23.0413 106.02C22.7818 100.657 23.4121 96.2669 25.0434 92.2301C25.9217 89.9348 27.3465 87.885 29.196 86.2559C31.9693 83.8618 34.9799 83.4567 38.4057 85.0257C38.8563 85.2431 39.2922 85.4892 39.7108 85.7624L30.3009 95.1251Z" fill="#5BBA47"/>
        <path d="M26.8174 79.7119C28.5311 79.4405 30.1677 78.8045 31.6181 77.8461C33.0685 76.8878 34.2994 75.6291 35.2287 74.1541C36.5171 72.1178 37.3663 69.8317 37.7212 67.4438H48V62.3107H37.7212C37.3881 60.3614 36.7313 58.4825 35.7784 56.7529C34.9033 55.1272 33.6911 53.7101 32.2241 52.5978C29.9384 50.9136 27.1798 50.0054 24.3474 50.0047C20.7347 49.9297 17.1597 50.7554 13.9406 52.4083C8.72165 55.096 4.15141 58.9032 0.551794 63.5617C0.177209 63.997 -0.0194448 64.5591 0.00151911 65.1347C0.0224831 65.7103 0.259505 66.2564 0.664749 66.6629L1.83194 67.9594C2.66027 68.892 3.51119 69.8474 4.42988 70.7573C7.39109 73.7548 10.8689 76.1853 14.6936 77.9301C17.4889 79.2486 20.5305 79.9541 23.617 80C24.69 79.9963 25.7607 79.8999 26.8174 79.7119ZM18.6696 62.341V67.4438H32.5931C32.5253 67.785 32.45 68.0959 32.3672 68.384C32.0035 69.7729 31.3459 71.0661 30.4394 72.1751C29.2547 73.5996 27.5842 74.5271 25.7556 74.7758C22.7503 75.2038 19.6865 74.7235 16.9527 73.3959C12.9857 71.5549 9.4282 68.927 6.49317 65.6696L5.91334 65.0327C9.59564 60.9383 13.2252 58.2087 17.3142 56.4344C19.6066 55.4111 22.107 54.9486 24.611 55.0848C28.3761 55.3502 30.8009 57.2381 32.1413 60.8473C32.3129 61.3261 32.4562 61.8148 32.5705 62.3107L18.6696 62.341Z" fill="#5BBA47"/>
        <path d="M27.367 48.5707C28.743 49.6048 30.3164 50.3239 31.9823 50.6798C33.6483 51.0358 35.3685 51.0205 37.0283 50.6351C39.3227 50.1031 41.484 49.0808 43.3734 47.6338L50.4922 55L54 51.3435L46.8664 44.0078C47.9738 42.3856 48.8107 40.5832 49.3426 38.6754C49.8531 36.895 49.9888 35.0223 49.7405 33.1831C49.3106 30.3466 48.0204 27.7246 46.0558 25.695C43.6078 23.0639 40.5706 21.0965 37.2125 19.9665C31.7475 18.1415 25.9618 17.5773 20.263 18.3135C19.7038 18.3541 19.1805 18.6126 18.7988 19.0369C18.4171 19.4612 18.2055 20.0194 18.2069 20.5988C18.2069 21.1777 18.1553 21.7567 18.1258 22.3356C18.0595 23.5164 17.9932 24.8571 18.0006 26.2054C17.9862 30.4422 18.7153 34.6466 20.1524 38.6145C21.5115 42.6201 24.0304 46.0963 27.367 48.5707ZM30.1379 34.0515L39.7918 43.9544C39.5118 44.1525 39.2539 44.3125 38.9959 44.4648C37.7895 45.179 36.4512 45.6228 35.0681 45.7674C33.2654 45.9243 31.4709 45.3811 30.0348 44.2439C27.6623 42.4073 25.8676 39.887 24.8762 36.9996C23.4015 32.8598 22.7449 28.4563 22.9454 24.0496C22.9454 23.7601 22.9454 23.4783 22.9454 23.1888C28.3177 22.907 32.7025 23.5621 36.7409 25.2303C39.035 26.1373 41.0865 27.5971 42.7248 29.4886C45.1273 32.3452 45.5326 35.4379 43.9555 38.9497C43.7382 39.4125 43.492 39.8604 43.2186 40.2904L33.5794 30.3875L30.1379 34.0515Z" fill="#5BBA47"/>
        </svg>

        <p> Preparing your summary report...</p>


        {/* <p>{progressPercentage}</p> */}
       </div>
        ):finalResultReady?( 
          <>
          {sendResultResponse && (
                <div className="flex flex-col pb-20  bg-white w-full mx-4 md:mx-2">
                <div className="flex flex-col pb-9 w-full bg-white">
                  <div className="flex flex-col justify-center items-start px-7 py-11 w-full backdrop-blur-[5px] md:px-5">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/58cac93733a372700047f42c90ca10db34646a0dc1054cf7551d463db7a4d6d0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                      className="w-16 aspect-[2.78]"
                    />
                    <div className='w-full h-[1px] bg-gray-400 mt-2 mb-10'></div>
                  </div>
            
              
                </div>
                <div className="flex md:flex-col gap-5 justify-between self-stretch m">
                      <div className="flex gap-3.5 my-auto text-2xl leading-6 text-sky-900 md:flex-wrap">
                        <div className="grow font-semibold">Summary Report</div>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/be62d8918412e08fc0d4847d9ebd1a10aa8fa643acbc394a29d63c04e4b23f92?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                          className="shrink-0 self-start w-0.5 border-2 border-solid aspect-[0.07] border-neutral-300 stroke-[2px] stroke-neutral-300"
                        />
                        <div className="flex-auto text-base">Climate change & strategy</div>
                      </div>
                      <div className="justify-center px-6 py-3 text-sm font-medium leading-6 text-black border border-black border-solid rounded-[29px] md:px-5">
                        Request full report
                      </div>
                    </div>

                  
 
                    <div className="flex gap-5 self-center mt-8 w-full maxw-[1101px] md:flex-wrap">
                      <div className="flex flex-col">
                        <div className="text-sm leading-6 text-neutral-700">
                          Author/Organisation
                        </div>
                        <div className="flex gap-3 px-5 py-2 mt-2 rounded-md bg-neutral-800">
                          <div className="text-sm font-medium text-zinc-100 leading-[48.93px]">
                            Egis
                          </div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/be42335cc37569b872b6459e0b59dd4bfa86c1cdfd22563728d9d3cf7c7073e5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                            className="shrink-0 w-0.5 border-2 border-solid aspect-[0.07] border-neutral-700 stroke-[2px] stroke-neutral-700"
                          />
                          <div className="flex-auto my-auto text-sm leading-6 text-zinc-100">
                            Nov 26 - Dec 27
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="grid grid-cols-2 gap-8 w-full justify-between">
                    <CustomProgressBar
                      label="Number of Questions Answered"
                      percentage={60}
                      completed={37}
                      total={70}
                      details={[
                        { color: "bg-yellow-500", text: "7 Unanswered" },
                        { color: "bg-blue-500", text: "63 Answered" },
                        { color: "bg-gray-300", text: "70 Assigned Questions" },
                      ]}
                    />
                    <CustomProgressBar
                      label="Number of Documents Provided"
                      percentage={20}
                      completed={14}
                      total={70}
                      details={[
                        { color: "bg-orange-500", text: "Documents" },
                        { color: "bg-blue-600", text: "Certifications" },
                      ]}
                    />
                    </div>
                    <div className='w-full h-[1px] bg-gray-400 my-4'></div>
                    <div className="self-center mt-3 w-full ">
                      <div className="flex md:flex-col md:gap-0">
                        <div className="flex flex-col w-6/12 md:ml-0 md:w-full">
                        <div className="flex-auto  text-base font-semibold leading-6 text-black">
                        Your Compliance
                          </div>
                                <div className="mt-7 w-full">
                                  <div className="flex gap-5 md:flex-col md:gap-0">
                                    <div className="flex flex-col w-[33%] md:ml-0 md:w-full">
                                      <div className="flex flex-col grow items-start py-4 pr-16 pl-6 font-medium rounded-lg border border-solid border-zinc-300 md:px-5 md:mt-4">
                                        <div className="text-base  text-black">
                                          Sustainability Standards
                                        </div>
                                        <div className="mt-2 text-base text-sky-900 leading-[49.2px]">
                                        {sendResultResponse.generalScore}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col ml-5 w-[33%] md:ml-0 md:w-full">
                                      <div className="flex flex-col grow items-start py-4 pr-20 pl-5 font-medium whitespace-nowrap rounded-lg border border-solid border-zinc-300 md:pr-5 md:mt-4">
                                        <div className="text-base  text-black">
                                          Regulations
                                        </div>
                                        <div className="mt-2 text-base text-sky-900 leading-[49.2px]">
                                        {sendResultResponse.evidenceScore}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col ml-5 w-[33%] md:ml-0 md:w-full">
                                      <div className="flex flex-col grow items-start py-4 pr-20 pl-5 font-medium whitespace-nowrap rounded-lg border border-solid border-zinc-300 md:pr-5 md:mt-4">
                                        <div className="text-base l text-black">
                                          Certifications
                                        </div>
                                        <div className="mt-2 text-base text-sky-900 leading-[49.2px]">
                                        {sendResultResponse.totalScore??''}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 md:ml-0 md:w-full">
                          <div className="flex-auto  text-base font-semibold leading-6 text-black">
                          Compliance Score
                          </div>
                          <div className="flex flex-col md:mt-2 w-full items-center justify-center">
                            <MyDoughnutChart />
                            <div className="flex gap-2  items-center justify-center py-3.5 mt-4 text-white-A700 bg-sky-900 rounded-md w-60">
                              <div className="  text-base font-medium">
                                Total score:{" "}
                              </div>
                              <div className="text-base font-semibold ">
                              {sendResultResponse.totalScore??""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
   

     <ESGEngagement />
                    </div>
                    <div className='w-full h-[1px] bg-gray-400 my-4'></div>
                  <div className='flex flex-col w-[60%] md:w-full'>
                  <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/6106ffb6a61cb819be4c4916b3f87ebab5215ea0d4de897bfc8298c2ad3834fe?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                      className="mt-4 aspect-[0.79] w-[41px]  md:ml-2.5"
                    />
                    <div className="mt-4 text-base font-semibold  text-black md:ml-2.5">
                      Strength
                    </div>
                    <div className="mt-2 text-sm  text-black">
                    {sendResultResponse.SWOT.Strengths}
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7d624b915c5a657973888819aab8d11a7ff62c95dcb9c4a03e8bd3b0a9849d0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                      className="mt-4 aspect-square w-[54px] md:mt-10 md:ml-2.5"
                    />
                    <div className="mt-2 text-base font-semibold  text-black md:ml-2.5">
                      Weakness
                    </div>
                    <div className="mt-2 text-base  text-black">
                    {sendResultResponse.SWOT.Weaknesses}
                    </div>
                <div className="flex flex-col items-start  mt-4 w-full  text-black md:px-5 md:mt-10">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/07f63cf3ec706450f317994cd2faa45f50da38fedade1c7a4cc0d72be1fd8f27?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                    className="mt-4 aspect-[1.1] md:ml-2.5"
                  />
                  <div className="mt-4 text-base font-semibold leading-10 md:ml-2.5">
                    Opportunity
                  </div>
                  <div className="mt-2 text-sm ">
                  {sendResultResponse.SWOT.Opportunities}
                  </div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c44aa5898a90d89aa7795c8ef7136f9f8c644f9c4e482b21ac0440669c73d76d?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                    className="mt-4  aspect-[0.94] w-[53px] md:mt-10 md:ml-2.5"
                  />
                  <div className="mt-4 text-base font-semibold ">
                    Threat
                  </div>
                  <div className="mt-2 text-sm leading-7">
                  {sendResultResponse.SWOT.Threats}
                  </div>
                  <div className='w-full  h-[1px] bg-gray-400 my-10'></div>
                  <div className="mt-6  text-base leading-6 font-semibold md:mt-10">
                    Background on Climate Change and Its Relevance to Certifications,
                    Regulations, and Sustainability Standards
                  </div>
                  <div className="self-center  mt-4 text-sm leading-6 text-neutral-900 ">
                    Climate change refers to long-term changes in temperature,
                    precipitation, and other atmospheric conditions on Earth. These
                    changes are primarily driven by human activities, notably the burning
                    of fossil fuels, deforestation, and industrial processes, which
                    increase the concentration of greenhouse gases (GHGs) such as carbon
                    dioxide (CO₂), methane (CH₄), and nitrous oxide (N₂O) in the
                    atmosphere. The resulting greenhouse effect leads to global warming
                    and a myriad of environmental impacts, including:
                   
                    Rising global temperatures
                   
                    Melting polar ice caps and glaciers
                   
                    Sea level rise
                   
                    Increased frequency and severity of extreme weather events (e.g.,
                    hurricanes, droughts, heatwaves)
                   
                    Disruption of ecosystems and biodiversity loss
                   
                    These changes pose significant risks to natural and human systems,
                    affecting agriculture, water resources, health, and infrastructure.
                    The urgency to address climate change has led to a global push for
                    mitigation and adaptation strategies.
                  </div>
                  <div className="justify-center self-center px-6 py-3 mt-10 text-sm font-medium leading-6 border border-black border-solid rounded-[29px] md:px-5 md:mt-10">
                    Request full report
                  </div>
                </div>
                  </div>
                

              
              </div>
          )}
       
          </>
          ):(
               <>
                   {isAnalysisOpen?( 
        
        <div   className="bg-white-A700 p-5 md:p-1 flex flex-col  justify-start dialog-inner   rounded-lg shadow-lg  ">
       
          <header className="flex md:flex-col self-stretch  mr-2 gap-5 md:mr-0 w-[70%] md:w-full">
              
              <div className="flex flex-col items-start md:mt-6 w-full gap-[22px]">
                <div className="flex sm:flex-col items-end  md:w-full gap-3">
                  <div className="flex flex-col items-start sm:self-stretch  ">
                   <Heading size="10xl" as="h3">
                   Waste Management
                        </Heading>
                    <div className="h-[2px] w-full my-1  relative bg-gray-300_06" />
                 
                    <Heading size="s" as="p" className="!text-blue_gray-600 mt-4">
                    Single-use-item - Scissors
                    </Heading>
                  </div>
              
                </div>
              <div className="w-full flex flex-row md:flex-col md:justify-start items-start justify-between gap-2 my-5">
               <button >
                <div className=" flex flex-row items-center gap-2">
                <Heading  as="h1" className="text-[#014E64] font-normal text-base sm:text-sm">
                 Response Overview
                </Heading>
            
                 </div> 
              </button>
    
              
    
              <div className='flex flex-row gap-4'>
                <button onClick={handlPreviewClose}  className="border border-gray-800 self-end font-normal  py-2 px-4 text-sm  rounded-[5px]">
                  Go back to question
                 </button>
                 <button  onClick={handleSendResult} className=" self-end font-normal bg-[#014E64] py-2 px-4 text-sm text-white-A700  rounded-[5px]">
                  Submit
                 </button>
              </div>
                
              </div>
                
              
               
              </div>
            </header>
            {/* <div>
            <h3>Final Result:</h3>
            <ul>
       
          
            </ul>
          </div> */}
    <div className="selected-options-div overflow-scroll w-full">       
         
         
    <ul className='flex flex-col gap-4'>
              {questions.map((question, index) => (
                <li key={index}>
                  <div className='flex flex-row gap-2 '>
                    <div className='h-1 w-1 bg-black-900_01 rounded-full mt-3'></div>
                    <div className='flex flex-col'>
                    <div  className="flex flex-row w-full gap-4 items-center">
    
    {question.text}  <button     onClick={() =>   handleEditMenuToggle('upload_evidence',index)}   className="border border-gray-800 self-end  font-normal p-1 text-xs  gap-1 rounded-md flex flex-row items-center">
                 Edit
                 <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.571428 9C0.408163 9 0.244898 8.9182 0.163265 8.8364C-1.58135e-07 8.67273 0 8.42734 0 8.26366L0.653061 6.30003C0.734694 6.13636 0.816326 5.89096 0.979592 5.72729L6.28571 0.490915C6.61225 0.163636 6.93878 0 7.42857 0C7.83674 0 8.2449 0.163636 8.57143 0.490915C9.14286 1.06364 9.14286 2.12728 8.57143 2.70005L3.26531 7.93639C3.10204 8.10007 2.93878 8.18186 2.69388 8.26366L0.734694 8.9182C0.734694 9 0.653061 9 0.571428 9ZM7.42857 1.14546C7.34694 1.14546 7.18367 1.22728 7.10204 1.30909L1.79592 6.54551L1.71429 6.6273L1.38776 7.52732L2.28571 7.20005C2.28571 7.20005 2.36735 7.20005 2.36735 7.11817L6.93878 2.70005L7.7551 1.88182C7.83673 1.80001 7.91837 1.71819 7.91837 1.55455C7.91837 1.39092 7.83673 1.39092 7.7551 1.22728C7.59184 1.14546 7.5102 1.14546 7.42857 1.14546Z" fill="#3E3E3E"/>
                </svg>
                </button>
    </div>
    
    
    <p className='text-sm mt-2'>Your Answers</p>
    <ul>
      {answers[index]?.map((answer, idx) => (
        <li key={idx} >
    
          {answer.name&&
          (<div className='flex flex-row gap-2 items-center'> <div className='h-2 w-2 bg-green-300'></div>  {answer.name} </div>)
    
          }
        
    
               
          <div>
         
            {groupedSearchDocs[answer.name] && groupedSearchDocs[answer.name][index] && (
              <div  className='flex flex-row gap-4 '>
                
               
               
                {groupedSearchDocs[answer.name][index].map((doc, docIdx) => (
                  <div key={docIdx}>
                    {doc.result && (
                                              (doc.result === 'very good' || doc.result === 'good')
                                                ? <div className='text-green-800 flex flex-row gap-1 '> <p className='text-sm'>{doc.name}</p></div>
                                                : (doc.result === 'Not a match')
                                                ? <div className='text-red-900 flex flex-row gap-1 '> <p className='text-sm'>{doc.name}</p></div>
                                                : (doc.result === 'Matched')
                                                ? <div className='text-green-800 flex flex-row gap-1 '> <p className='text-sm'>{doc.name}</p></div>
                                                : <div className='text-red-900 flex flex-row gap-1 '> <p className='text-sm'>{doc.name}</p></div>
                                            )}
                 
                  </div>
                ))}
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
                    </div>
                  </div>
                 
                </li>
              ))}
            </ul>
    
    </div>
    
        </div>):(
            <div className="flex flex-row  md:flex-col  mt-20  w-full">
              <div className="flex flex-col items-start py-5 px-10 md:px-5 border-r md:border-none  border-gray-300   w-[65%] md:w-full ">
                <header className="flex md:flex-col self-stretch  mr-2 gap-5 md:mr-0">
              
                  <div className="flex flex-col items-start w-[90%] md:mt-6 md:w-full gap-[22px]">
                    <div className="flex sm:flex-col items-end  md:w-full gap-3">
                      <div className="flex flex-col items-start sm:self-stretch  ">
                       <Heading size="10xl" as="h3">
                       Waste Management
                            </Heading>
                        <div className="h-[2px] w-full my-2  relative bg-gray-300_06" />
                     
                        <Heading size="s" as="p" className="!text-[#456C78]">
                        Single-use - Scissors
                        </Heading>
                      </div>
                      <div className="flex flex-row items-center  justify-center h-10  ">
                      <Img src="/images/img_plus_deep_orange_a400.svg" alt="plus_one" className="h-2 w-2" />


    <div
    
      className="ml-2 h-10 flex items-center  text-xs  px-4 w-auto !text-green-800 font-semibold bg-[#E5FCED] rounded-full "
    >
         <Heading size="s" as="p" className="">
         ISO 14040:2006 Clause 4.2.3( Life Cycle Inventory Analysis)
                        </Heading>
    
    
     </div>
 
                      </div>
                      
                    </div>
                  <div className="w-full flex flex-row items-center justify-between my-5">
                   <button  onClick={() => handleMenuToggle('assign_user')}  >
                    
                    <div className=" flex flex-row items-center gap-2">
                    <Heading  as="h1" className="text-[##014E64] font-normal text-base sm:text-sm">
                   Assign this question to another user
                 </Heading>
                   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <circle cx="10" cy="10" r="10" fill="#5BBA47"/>
                   <path d="M7 10H13" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M10 13V7" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                     </div> 
                  </button>
    
                  
                   
                 
             <div>
             {currentQuestionIndex < questions.length - 1 && (
                  <Button color="teal_50_02" className="bg-cyan-300 sm:pr-5 font-medium min-w-[101px] rounded-[15px]  hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]" onClick={handleNextQuestion}>
                  Skip
                </Button>
                )}
                {currentQuestionIndex === questions.length - 1 && (
                  <Button color="teal_50_02" className="bg-cyan-300 sm:pr-5 font-medium min-w-[101px] rounded-[15px]  hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]" onClick={handlPreviewSection}>
                  Preview
                </Button>
                )}
              </div>
               </div>
                    
                  
                   
                  </div>
                </header>
              
       <div className="w-full my-10">
         {loading && 
          <div className='flex flex-col gap-4'>
                <div  className="flex  w-full h-16 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse my-2 "/>
    
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
    
       </div>
       }
          {!loading && questions.length === 0 && <p>No questions found.</p>}
          {!loading && questions.length > 0 && (
            <>
            
              {/* <p>Question {currentQuestionIndex + 1} of {questions.length}</p> */}
              <div>
                <p className="text-xl md:text-base font-bold">{questions[currentQuestionIndex].text}</p>
    
    
                <ul>
                 <div>
              {currentQuestion.options.map((option, index) => (
                <div key={index}   className="border animate__animated animate__fadeInUp  border-gray-100 p-5 my-3 gap-2 rounded-md flex flex-row items-cente hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]r  w-full">
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name="selectedAnswer"
                    value={option.text}
                    checked={answers[currentQuestionIndex].some(ans => ans.name === option.text)}
                    onChange={() => handleAnswerChange(option.text, option.evd,option.evdText,option.tips,option.point,currentQuestionIndex)}
                  />
                  <label htmlFor={`option-${index}`}>{option.text}</label>
                </div>
              ))}
            </div>
                </ul>
              </div>
            
              <div className="flex flex-row justify-between w-full mt-10">
                {currentQuestionIndex > 0 ?(
                   <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0} color="teal_50" className="bg-cyan-300 sm:px-5 font-medium min-w-[101px] rounded-[15px]">
                   Back
                  </Button>
                ):(  <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0} color="teal_50" className="invisible bg-cyan-300 sm:px-5 font-medium min-w-[101px] rounded-[15px]">
                Back
               </Button>)}
               {!loading && questions.length > 0 && (
            <>
                 <div>
                 {answers[currentQuestionIndex].map((answer, index) => {
  // Safeguard against undefined values
  const currentDocs = groupedSearchDocs[answer.name];
  const currentDocForIndex = currentDocs ? currentDocs[currentQuestionIndex] : null;

  return (
    <div key={index} className="">
      {index === 0 && (
        <div className="">
          <div>
            {currentDocForIndex && currentDocForIndex.length === 0 ? (
              <div>
                <p className="text-sm font-medium">no selected</p>
              </div>
            ) : (
              currentDocForIndex && (
                <div>
                  {currentQuestionIndex < questions.length - 1 && (
                    <Button
                      color="teal_50_02"
                      className="bg-cyan-300 sm:pr-5 font-medium min-w-[101px] rounded-[15px] hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]"
                      onClick={handleNextQuestion}
                    >
                      Continue
                    </Button>
                  )}
                  {currentQuestionIndex === questions.length - 1 && (
                    <Button
                      color="teal_50_02"
                      className="bg-cyan-300 sm:pr-5 font-medium min-w-[101px] rounded-[15px] hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]"
                      onClick={handlPreviewSection}
                    >
                      Preview
                    </Button>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
})}


          </div>
             
            </>
           )}
             
              </div>
    
              <div className="w-full flex flex-row h-10 mt-10 mb-10 gap-2" >
                          <ProgressBar className="w-full"  completed={progress} customLabel=" " bgColor = "#1BD1EA"/> 
                          <Text size="xl"  className="!text-gray-400_01">
                         {currentQuestionIndex+1}/{questions.length}
                        </Text>
                                      </div> 
            </>
           )}
          </div>  
         
         
        
      
          
    
      
               
              </div>
               <div className="flex flex-col  md:mt-10  px-10 md:px-5  w-[35%]  md:w-full  md:ml-0">
              {answers[currentQuestionIndex] ? (
          <div className="no-selection-div">
           
          </div>
        ) : (
          <p  className="mt-1.5 text-sm font-semibold !text-red-600 animate__animated animate-pulse">
                           You must upload an evidence for this option to continue. Search or upload document!
                         </p>
        )}
         
             <p className="mt-4 mb-1 text-sm font-semibold">Tool Tip: Find tips to answer questions here</p>
             <div
               className= "flex flex-col items-center w-full text-sm bg-light_blue-50 self-stretch mt-[5px] gap-2 p-5 font-medium !rounded-[5px] max-h-52 overflow-scroll"
             >
              <div className="flex flex-row items-center justify-start w-full "> <Img
                     src="/images/img_vector_deep_orange_400.svg"
                     alt="vector_one"
                     className="h-6 w-6  "
                   />
                Tips </div>
    
    
                <div className="w-full">
         {loading && 
          <div className='flex flex-col gap-4'>
               
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
    
       </div>
       }
          {/* {!loading && questions.length === 0 && <p>No questions found.</p>} */}
          {!loading && questions.length > 0 && (
            <>
            
              {/* <p>Question {currentQuestionIndex + 1} of {questions.length}</p> */}
              <div>
            {answers[currentQuestionIndex] ? (
              answers[currentQuestionIndex].length === 0 ? (
                <p>No answers selected.</p>
              ) : (
                <div className="selected-answers">
                  <ul>
                    {answers[currentQuestionIndex].map((answer, index) => (
                      <li key={index}>
                       
                       <div
                     
                     className="my-2 animate__animated animate__fadeInUp rounded-md flex flex-row items-center  hover:-translate-x-[1%] hover:-translate-y-[2%] w-full"
    
                   >
                   <p className="text-sm"> <b>{answer.name}</b>{answer.tips}</p> 
                   </div>
    
                       
                      </li>
                    ))}
                  </ul>
                </div>
              )
            ) : (
              <p>No selected question.</p>
            )}
          </div>
          
            </>
           )}
          </div>  
                
    
     
             </div>
    
             <p className="mt-4 mb-1 text-sm font-semibold">Suggested evidences you need to answer this quention</p>
             <div
               className= "flex flex-col items-center w-full text-sm bg-[#F6FFFA] border border-green-500 self-stretch my-5 gap-2 p-5 font-medium !rounded-[5px] max-h-52 overflow-scroll"
             >
              <div className="flex flex-row items-center justify-start w-full gap-2 ">
              <Img src="/images/img_group_363.svg" alt="Group 363" />
               Evidence
                
                 </div>
                 <div className="w-full">
         {loading && 
          <div className='flex flex-col gap-4'>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
    
       </div>
       }
          {/* {!loading && questions.length === 0 && <p>No questions found.</p>} */}
          {!loading && questions.length > 0 && (
            <>
                 <div>
            {answers[currentQuestionIndex] ? (
              answers[currentQuestionIndex].length === 0 ? (
                <p>No answers selected</p>
              ) : (
                <div className="selected-answers">
                  <ul>
                    {answers[currentQuestionIndex].map((answer, index) => (
                      <li key={index}>
                       
                       <div
                     
                     className="my-2 animate__animated animate__fadeInUp rounded-md flex flex-row items-center  hover:-translate-x-[1%] hover:-translate-y-[2%] w-full"
    
                   >
                    <p className="text-sm">{String.fromCharCode(97 + index)}. {answer.evdTxt}</p> 
                   </div>
    
                       
                      </li>
                    ))}
                  </ul>
                </div>
              )
            ) : (
              <p>No selected question.</p>
            )}
          </div>
             
            </>
           )}
          </div>  
                <ul>
                  {/* {questions[currentQuestionIndex].options.map((option ,index: number) => (
                    <li key={option._id}>               
                       <div
      key={index}
      className=" animate__animated animate__fadeInUp rounded-md flex flex-row items-center  hover:-translate-x-[1%] hover:-translate-y-[2%] w-full"
    
    >
      <p className="text-sm">{String.fromCharCode(97 + index)}. {option.evd}</p> 
    </div>
    
    
          
                    </li>
                  ))} */}
                </ul>
      
     
             </div>
    
             <p className="mt-4 mb-1 text-sm font-semibold">Your Upload History</p>
             <div
               className= "flex flex-col items-center w-full text-sm  border border-gray-500 self-stretch my-5 gap-2 p-5 font-medium !rounded-[5px] max-h-52 overflow-scroll"
             >
                 <div className="w-full">
         {loading && 
          <div className='flex flex-col gap-4'>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
    
       </div>
       }
          {/* {!loading && questions.length === 0 && <p>No questions found.</p>} */}
          {!loading && questions.length > 0 && (
            <>
                 <div>
                 {answers[currentQuestionIndex].map((answer, index) => {
  // Safeguard against undefined values
  const docs = groupedSearchDocs[answer.name]?.[currentQuestionIndex] || [];

  return (
    <div key={index} className="my-2">
      {/* <label className="font-medium text-sm">{answer.name}</label> */}
      <div>
        {docs.length === 0 ? (
          <div>
            <p className="text-sm font-medium">no selected</p>
          </div>
        ) : (
          <div>
            {docs.map((doc, idx) => (
              <div key={idx} className="text-xs w-full flex flex-row items-center justify-between">
                <p>{doc.name}</p>
                <div className="flex flex-row gap-1 ml-1">
                  {doc.result && (
                    <>
                      {doc.result === 'very good' || doc.result === 'good' ? (
                        <div className="text-green-800 flex flex-row gap-1">{doc.result}</div>
                      ) : doc.result === '0' ? (
                        <div className="text-red-900 flex flex-row gap-1">Mismatched</div>
                      ) : doc.result === '1.0' ? (
                        <div className="text-green-800 flex flex-row gap-1">Matched</div>
                      ) : (
                        <div className="text-red-900 flex flex-row gap-1">{doc.result}</div>
                      )}
                    </>
                  )}
                  <button
                    onClick={() => setSearchDocs((prevDocs) => prevDocs.filter((_, i) => i !== idx))}
                    className="ml-2 text-white px-2 py-1 rounded-md"
                  >
                    <svg
                      className="w-3 h-3"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
})}

          </div>
             
            </>
           )}
          </div>  
                <ul>
           
                </ul>
      
     
             </div>
           
           
           
             <div
               className= "flex "
             >
                 <div className="w-full">
         {loading && 
          <div className='flex flex-col gap-4'>
                
    
       </div>
       }
          {/* {!loading && questions.length === 0 && <p>No questions found.</p>} */}
          {!loading && questions.length > 0 && (
            <>
                 <div>
            {answers[currentQuestionIndex] ? (
              answers[currentQuestionIndex].length === 0 ? (
                <p>No answers selected</p>
              ) : (
                <div className="w-full flex justify-end items-end">
               
                  <Button
           onClick={() => handleMenuToggle('upload_evidence')}
       
          color="gray_700_03"
          size="6xl"
          className="bg-black-900_01 w-60 md:w-full text-white-A700  mb-2 sm:px-5 font-hankengrotesk  rounded-[10px]  hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]"
          >
          {loading ?    <CgSpinner className=" animate-spin text-lg " /> :  <p className="flex items-center gap-1">Upload Evidence
                              
                          </p> } 
                 </Button>
                </div>
              )
            ) : (
              <p>No selected question.</p>
            )}
          </div>
             
            </>
           )}
          </div>  
                <ul>
        
                </ul>
      
     
             </div>
             
    
    
               
              </div>
            </div>
            )}  
           </>
            )}  
           
    
       
      </div>
<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
 
{isMenuOpen && (
   <>
    {dialogType === 'assign_user'?(
      <div  onClick={handlenewCloseMenu} className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none  bg-black-900_01 bg-opacity-50 "  >
  <div  onClick={(e) => e.stopPropagation()} className="z-50 bg-white-A700 p-5 flex flex-col justify-between dialog-inner h-[35%] md:h-[40%] w-[80%] rounded-lg shadow-lg maxw-md ">
    <div className="flex flex-row w-full  justify-between items-center ">
      <h3 className="font-normal text-black ">Assign this question to another user or email</h3>
      <button
        onClick={handleassignCloseMenu}
        className="text-gray-600 hover:text-gray-800 focus:outline-none text-black dark:text-white"
      >
        <svg
          className="w-5 h-5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
<div className='h-10 w-full mt-2 border flex justify-center gap-2 items-center border-gray-400 rounded-md px-2'>
<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 7C4.57043 7 3 5.42956 3 3.5C3 1.57043 4.57043 0 6.5 0C8.42956 0 10 1.57043 10 3.5C10 5.42956 8.42956 7 6.5 7ZM6.5 0.913043C5.07565 0.913043 3.91304 2.07565 3.91304 3.5C3.91304 4.92435 5.07565 6.08696 6.5 6.08696C7.92435 6.08696 9.08696 4.92435 9.08696 3.5C9.08696 2.07565 7.92435 0.913043 6.5 0.913043Z" fill="#4D4D4D"/>
<path d="M12.4781 14C12.1927 14 11.9561 13.76 11.9561 13.4706C11.9561 11.0353 9.50648 9.05882 6.50005 9.05882C3.49358 9.05882 1.0439 11.0353 1.0439 13.4706C1.0439 13.76 0.807283 14 0.52195 14C0.236617 14 0 13.76 0 13.4706C0 10.4565 2.91596 8 6.50005 8C10.0841 8 13 10.4565 13 13.4706C13 13.76 12.7634 14 12.4781 14Z" fill="#4D4D4D"/>
</svg>
<input
 type="text"
 placeholder="Search for a user..."
 value={searchQuery}
 onChange={handleSearchQueryChange}
/>
</div>    

<div className='h-[50%] md:h-[70%] overflow-scroll'>
{searchQuery.trim() !== '' && (
          <ul>
    {searchResults.map((result, index) => (
      <li className='p-2 bg-[#F5F5F5] my-2' key={index} onClick={() => handleUserClick(result)}>{result}</li>
    ))}
  </ul>
)}
</div>

    <div className="flex flex-row w-full justify-between items-center">
    <Img src="/images/sendinvite.png" alt="plus_one" className="h-6" />
 <button  className="h-10 w-36 text-black-900_01 bg-[#5FE1F3] rounded-lg border border-strokedark shadow-default dark:border-stroke flex flex-row items-center justify-center gap-2" onClick={asignusermoveToNextQuestion}> <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.04973 1.04164L13.8176 7L1.04994 12.9583L3.78475 7.21596L3.88713 7.001L3.78477 6.78603L1.04973 1.04164Z" stroke="black"/>
<path d="M4 7H7.04138" stroke="black" stroke-linejoin="round"/>
</svg>
Send</button>
    </div>
  </div>
      </div>
 ):( 
     <div  onClick={handlenewCloseMenu} className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none  bg-black-900_01 bg-opacity-50 "  >
          <div  onClick={(e) => e.stopPropagation()} className="z-40 bg-white-A700 p-5 md:p-1 flex flex-col items-center justify-start dialog-inner md:h-full h-[80%] md:w-full w-[80%]  rounded-lg shadow-lg  ">
            <div className="md:hidden -mb-14 md:mb-2 flex flex-row w-full  justify-end items-center ">
             
              
            <Button
            onClick={handleSubmit}
            color="gray_700_03"
            size="6xl"
            className="bg-black-900_01 md:w-full w-40 text-sm text-white-A700 mt-9 mb-2 px-5 font-hankengrotesk  rounded-[10px]  hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]"
            >
              <p className="flex text-xs items-center gap-1">Analyze 
                              
            </p> 
            </Button>
            </div>
            <div className="flex flex-row md:w-full   justify-center items-center w-auto ">
              <div className="flex flex-col justify-center items-center gap-1">
              
              <h3 className="font-semibold text-lg md:text-sm text-black ">Upload Evidence</h3>
              <p className="mt-2 mb-2 text-sm text-center md:text-xs font-semibold">Select question and upload evidence. You can upload <span className="text-green-500">multiple evidences</span>  to one option</p>
              <div className="flex flex-row my-5  items-center justify-center  gap-[11px] w-full sm:px-5 bg-gray-200 border-cyan-200 border border-solid rounded-[7px]">
            
          <div>
          {/* <Autocomplete
            options={files.map((cert) => files.name)}
            renderInput={(params) => <TextField {...params} label="Search document manager" />}
            onChange={(event, value) => {
              
              handleAutocompleteChange(event, value, selectedCert.link);
            }}
            style={{ width: 500 }}
              sx={{ 
                width: '100%' ,
                  '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none', // Remove the default border
                        },
                        '&:hover fieldset': {
                          border: 'none', // Remove the hover border
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none', // Remove the focused border
                        },
                      },
                }}
            
          /> */}
        <Autocomplete
  options={files.map((file) => file.name)}  // Map over file names correctly
  renderInput={(params) => <TextField {...params} label="Search document manager" />}
  onChange={(event, value) => {
    const selectedCert = files.find((file) => file.name === value);
    handleAutocompleteChange(event, value, selectedCert?.name);  // Pass the correct link if found
  }}
  style={{ width: 500 }}
  sx={{
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Remove the default border
      },
      '&:hover fieldset': {
        border: 'none', // Remove the hover border
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Remove the focused border
      },
    },
  }}
/>

          
          </div>
       
         
      
     </div> 
              </div>
              
              
   
            </div>
     <div className="selected-options-div overflow-scroll w-full">       
            <ul className="flex flex-col gap-4 w-full">

            {answers[currentQuestionIndex] ? (
          answers[currentQuestionIndex].length === 0 ? (
            <p></p>
          ) : (
             <ul className=" flex flex-col gap-2 w-full">
            {answers[currentQuestionIndex].map((answer, index) => (
             <li key={index} className=" flex flex-row md:flex-col  w-full justify-between my-2">

             <div className="flex flex-col w-[45%] md:w-full bg-gray-100_03 rounded-md items-start p-2 h-52 md:h-auto ">
              <div className='flex flex-row gap-4'> <input
                                   type="radio"
                                   id={answer.name}
                                   name="selectedAnswerDisplay"
                                   value={answer.name}
                                   checked={selectedAnswer === answer.name}
                                   onChange={() => handleSelectedAnswer(answer.name,answer.evd)}
                                 />
                                 <label htmlFor={answer.name}>{answer.name}</label>
             </div> 
             
             {/* <p className="text-sm font-semibold mb-2"> {option.text} </p>  */}
             <div>
                                   {groupedSearchDocs[answer.name]&& groupedSearchDocs[answer.name][currentQuestionIndex]  && (
                                     <div>
                                      
                                       {groupedSearchDocs[answer.name][currentQuestionIndex].map((doc, idx) => (
                                         <div key={idx} className="text-xs w-full flex flex-row items-center justify-between ">
                                           
                                           <p>{doc.name}</p>
                                           <div className='flex flex-row gap-1 ml-1'>
                                           {doc.result && (
                                              (doc.result === 'very good' || doc.result === 'good')
                                                ? <div className='text-green-800 flex flex-row gap-1 '>{doc.result}</div>
                                                : (doc.result === 'Not a match')
                                                ? <div className='text-red-900 flex flex-row gap-1 '>Not A Matched</div>
                                                : (doc.result === 'Matched')
                                                ? <div className='text-green-800 flex flex-row gap-1 '>Matched</div>
                                                : <div className='text-red-900 flex flex-row gap-1 '>{doc.result}</div>
                                            )}
                                           <button
                             onClick={() => handleRemoveDoc(doc.filePart)}
                             className="ml-2  text-white px-2 py-1 rounded-md"
                                 >
                             <svg
                               className="w-3 h-3"
                               fill="none"
                               strokeLinecap="round"
                               strokeLinejoin="round"
                               strokeWidth="2"
                               viewBox="0 0 24 24"
                               stroke="currentColor"
                             >
                               <path d="M6 18L18 6M6 6l12 12"></path>
                             </svg>
                              </button>
                                           
                                       </div>   
                                         </div>
                                       ))}
                                     </div>
                                   )}
                                 </div>
             
             </div>
             <div className="flex flex-col w-[45%] md:w-full item-center justify-center h-52 md:h-auto ">
             
             {/* <p className="text-sm font-semibold mb-2"> {option.text} </p>  */}
             <div className=' h-full w-full'>
                
                      
                     
               <label   htmlFor="image-upload"    className={` flex flex-col items-center justify-center p-4 rounded-[7px]  h-52  w-full md:h-auto cursor-pointer  ${
                     curentAnswername === answer.name  ?
                      'border border-[#45AEDB] border-dashed bg-[#9AC6EE] bg-opacity-20' :'border border-[#A5A5A5] border-dashed bg-gray-100_03'
                   }`} >
               <input  className='opacity-0'
                           type="file"
                           id="image-upload"
                           multiple
                           style={{ display: 'none' }}
                           onChange={handleFileUpload}
                         />
              <div className="flex items-center justify-center my-2  w-full">
                <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 0V7.25V7.32889C11.4999 7.9438 11.4997 8.54137 11.5654 9.03426C11.6394 9.58989 11.82 10.224 12.3421 10.7506C12.8642 11.2773 13.4928 11.4593 14.0437 11.534C14.5323 11.6003 15.1247 11.6001 15.7343 11.6H15.8125H23V20.3C23 24.4012 23 26.4518 21.7369 27.7259C20.4737 29 18.4408 29 14.375 29H8.625C4.55913 29 2.52621 29 1.2631 27.7259C3.42727e-07 26.4518 0 24.4012 0 20.3V8.7C0 4.59878 3.42727e-07 2.54817 1.2631 1.27409C2.52621 0 4.55913 0 8.625 0H11.5ZM14.375 0.00669911V7.25C14.375 7.97452 14.378 8.37205 14.4148 8.64783L14.4163 8.6584L14.4268 8.65985C14.7002 8.69693 15.0942 8.7 15.8125 8.7H22.9934C22.9787 8.10312 22.9317 7.70813 22.7812 7.34144C22.5623 6.8085 22.1468 6.38941 21.3158 5.55122L17.4967 1.69878C16.6657 0.86059 16.2502 0.441496 15.7218 0.220748C15.3583 0.0688606 14.9667 0.0214746 14.375 0.00669911Z" fill="#E7EFF5"/>
                <circle cx="21.5" cy="26.5" r="8.5" fill="#086AFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 21C20.205 21 19.0912 21.8497 18.5936 23.0682C18.5458 23.1854 18.5119 23.2683 18.4858 23.3287C18.4729 23.3586 18.4631 23.3799 18.4557 23.3951C18.4502 23.4064 18.4472 23.4116 18.4466 23.4128C18.4465 23.4129 18.4466 23.4127 18.4466 23.4128C18.4066 23.4741 18.3854 23.493 18.3731 23.5018C18.3607 23.5106 18.3364 23.5242 18.2685 23.5392C18.2479 23.5437 18.2052 23.5455 18.0263 23.5455C16.9072 23.5455 16 24.5426 16 25.7727C16 27.0028 16.9072 28 18.0263 28H18.4151L18.9941 27.3636H18.0263C17.227 27.3636 16.5789 26.6514 16.5789 25.7727C16.5789 24.8941 17.227 24.1818 18.0263 24.1818L18.0573 24.1818C18.1882 24.182 18.2969 24.182 18.3827 24.1631C18.4874 24.1399 18.5903 24.1053 18.6876 24.036C18.785 23.9668 18.8559 23.8777 18.9179 23.7823C18.9515 23.7307 18.9814 23.665 19.0086 23.602C19.0378 23.5345 19.0743 23.4451 19.1203 23.3326L19.1218 23.3288C19.5294 22.3306 20.4413 21.6364 21.5 21.6364C22.5587 21.6364 23.4706 22.3306 23.8782 23.3288L23.8798 23.3327C23.9257 23.4452 23.9622 23.5345 23.9914 23.602C24.0186 23.665 24.0485 23.7307 24.082 23.7823C24.1441 23.8777 24.215 23.9668 24.3124 24.036C24.4097 24.1053 24.5126 24.1399 24.6173 24.1631C24.7031 24.182 24.8118 24.182 24.9427 24.1818L24.9737 24.1818C25.773 24.1818 26.4211 24.8941 26.4211 25.7727C26.4211 26.6514 25.773 27.3636 24.9737 27.3636H24.0059L24.5849 28H24.9737C26.0928 28 27 27.0028 27 25.7727C27 24.5426 26.0928 23.5455 24.9737 23.5455C24.7948 23.5455 24.7521 23.5437 24.7315 23.5392C24.6636 23.5242 24.6393 23.5106 24.627 23.5018C24.6146 23.493 24.5935 23.4743 24.5536 23.413C24.553 23.4119 24.5498 23.4064 24.5443 23.3951C24.5369 23.3799 24.5271 23.3586 24.5142 23.3287C24.4881 23.2683 24.4542 23.1854 24.4064 23.0682C23.9088 21.8497 22.795 21 21.5 21Z" fill="white"/>
                <path d="M21.5 26.4849L21.2157 26.2424L21.5 26L21.7843 26.2424L21.5 26.4849ZM21.9019 32.6571C21.9019 32.8465 21.7219 33 21.5 33C21.278 33 21.098 32.8465 21.098 32.6571H21.9019ZM18 28.9856L21.2157 26.2424L21.7843 26.7274L18.5685 29.4706L18 28.9856ZM21.7843 26.2424L25 28.9856L24.4315 29.4706L21.2157 26.7274L21.7843 26.2424ZM21.9019 26.4849V32.6571H21.098V26.4849H21.9019Z" fill="white"/>
                </svg>
                <p  className="self-end flex flex-row !text-gray-900_05 text-sm px-2">
                  Drag and drop file or
                  <div     className="underline  text-gray-900_05 text-sm px-1">
                choose file
                </div>
                </p>
                
               </div>
              
              
             </label>
            
               </div>
             
             </div>
             
             
             
             </li>
            ))}
          </ul>
          )
        ) : (
          <p>No answers available for this question.</p>
        )}

</ul>

      </div>
      <div className="md:block hidden flex-row w-full  justify-end items-center ">
             
              
             <Button
              onClick={handleSubmit}
            color="gray_700_03"
            size="6xl"
            className="bg-black-900_01 md:w-full w-40 text-sm text-white-A700 mt-2 mb-2 px-5 font-hankengrotesk  rounded-[10px]  hover:shadow hover:-translate-x-[1%] hover:-translate-y-[2%]"
            >
            <p className="flex text-xs items-center gap-1">Analyse 
                              
                          </p> 
            </Button>
           </div>
          </div>
     </div>
        )}
   </>
 
         
        )}
      </DefaultLayout>
   
    </>
   
    </>
  );
};

export default WasteQuestions;
